/* eslint-disable @treasury/no-date */
import {
    CompanyAccountModelDto,
    CurrencyModelDto,
    WireCompanyModelDto,
} from '@treasury/api/channel';
import { StatusModel } from '@treasury/domain/wires';
import { OmegaDate } from '@treasury/omega/components/datepicker';
import { OmegaReportFilter } from '@treasury/omega/types';
import { OmegaListItem } from '@treasury/omega/view-models';
import FieldTypes from '@treasury/policy/primitives';
import { amountFilter } from '@treasury/policy/wires';
import {
    DebitAccountListItem,
    WireCompanyListItem,
    createDebitAccountListItem,
    createWireCompanyListItem,
} from '../../models';
import { WireStatusListItem } from '../../models/wire-status-list-item';
import { WirePaymentActivityFilterParameters } from '../models/filter-params.model';

const wireStatusOptions: StatusModel[] = [
    { name: 'Pending Approval', id: 1 },
    { name: 'Approval Rejected', id: 2 },
    { name: 'Transmitted', id: 4 },
    { name: 'Cancelled', id: 5 },
    { name: 'Posted', id: 6 },
    { name: 'Scheduled', id: 7 },
    { name: 'Failed', id: 8 },
    { name: 'Expired', id: 9 },
    { name: 'Deleted', id: 10 },
];

const statusTypesList: OmegaListItem<StatusModel>[] = wireStatusOptions.map(type => ({
    text: type.name,
    value: type,
}));

export function createCurrencyListItem(currency: CurrencyModelDto): OmegaListItem<string> {
    return {
        value: currency.currencyCode || '',
        text: `${currency.currencyCode}, ${currency.name}`,
    };
}

const today = new Date();
const oneWeekLater = new Date();
oneWeekLater.setDate(today.getDate() + 7);

const nextWeek: OmegaDate = {
    id: 'range',
    exact: true,
    isRange: true,
    label: '',
    dates: [today, oneWeekLater],
};

export function createFilters(
    wireCompanies: WireCompanyModelDto[],
    debitAccounts: CompanyAccountModelDto[],
    availableCurrencies: CurrencyModelDto[]
): OmegaReportFilter<WirePaymentActivityFilterParameters, any>[] {
    return [
        {
            field: 'wireCompany',
            fieldType: FieldTypes.string.with
                .label('Wire Company')
                .and.options({
                    data: wireCompanies.map(a => createWireCompanyListItem(a)),
                })
                .with.placeholder('All Selected')
                .with.multipleValues()
                .thatHas.hashFunction<WireCompanyListItem['value']>(v => v.name)
                .as.tag('omega-select'),
            value: wireCompanies,
        },
        {
            field: 'type',
            fieldType: FieldTypes.string.with
                .label('Wire Type')
                .thatHas.options([
                    { text: 'Both', value: 'Both' },
                    { text: 'Domestic', value: 'Domestic' },
                    { text: 'International', value: 'International' },
                ])
                .thatIs.inline()
                .as.tag('omega-radio-group'),
            value: 'Both',
        },
        {
            field: 'status',
            fieldType: FieldTypes.string.with
                .label('Status')
                .and.options({
                    data: statusTypesList,
                })
                .with.placeholder('All Selected')
                .with.multipleValues()
                .thatHas.hashFunction<WireStatusListItem['value']>(v => v.name!)
                .as.tag('omega-select'),
            value: [],
        },
        {
            field: 'debitAccount',
            fieldType: FieldTypes.string.with
                .label('Debit Account')
                .and.options({
                    data: debitAccounts.map(a => createDebitAccountListItem(a)),
                })
                .with.placeholder('All Selected')
                .with.multipleValues()
                .thatHas.hashFunction<DebitAccountListItem['value']>(
                    v => v.accountDisplayLabel || ''
                )
                .as.tag('omega-select'),
            value: debitAccounts,
        },
        {
            field: 'beneficiaryName',
            fieldType: FieldTypes.string.with.label('Beneficiary Name').as.tag('omega-input'),
            value: '',
        },
        {
            field: 'transactionId',
            fieldType: FieldTypes.string.with.label('Transaction ID').as.tag('omega-input'),
            value: '',
        },
        {
            field: 'omad',
            fieldType: FieldTypes.string.with.label('OMAD').as.tag('omega-input'),
            value: '',
        },
        {
            field: 'currency',
            fieldType: FieldTypes.string.with
                .label('Currency')
                .and.options({
                    data: availableCurrencies.map(a => createCurrencyListItem(a)),
                })
                .with.placeholder('Select Currency')
                .as.tag('omega-select'),
            value: '',
        },
        {
            field: 'amount',
            fieldType: amountFilter.with
                .schema('range')
                .and.label('Wire Amount')
                .as.tag('omega-range'),
            value: ['specific', 0],
        },
        {
            field: 'effectiveDate',
            fieldType: FieldTypes.dateRange.with
                .placeholder('Effective Date')
                .with.label('Effective Date')
                .as.tag('omega-datepicker'),
            value: nextWeek,
        },
        {
            field: 'createdDate',
            fieldType: FieldTypes.dateRange.with
                .placeholder('Created Date')
                .with.label('Created Date')
                .as.tag('omega-datepicker'),
            value: '',
        },
    ];
}
