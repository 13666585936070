import { Injectable } from '@jack-henry/frontend-utils/di';
import { UsersClient as BoUsersClient } from '@treasury/api/bo';
import { ApprovalListRequestModelDto, UserDto, UserTimeAccess2Dto } from '@treasury/api/channel';
import {
    UsersClient as ChannelUsersClient,
    UsersClient,
} from '@treasury/api/channel/generated/Users';
import { RequestParams } from '@treasury/api/channel/generated/http-client';
import { AppType, ConfigurationService } from '@treasury/core/config';
import { TmHttpClient } from '@treasury/core/http';
import { mapUserTimeAccess } from '../backoffice/mappings/time-access/map-user-time-access-response';
import { UserRequests } from '../channel/requests/users';
import { AchEntitlements, AchLimits } from './product-features';
import { IntegratedServicesEntitlements } from './product-features/integrated-services-entitlements.entity';
import { ProductFeatureType as Feature } from './product-features/productFeature.type';
import { UserAccountAccess } from './product-features/user-account-access.entity';
import { UserIpRestrictions } from './product-features/user-ip-restrictions.entity';
import { UserReceivablesSettings } from './product-features/user-receivables-settings.entity';
import { UserTimeAccess } from './product-features/user-time-access.entity';
import { UserTransferLimits } from './product-features/user-transfer-limits.entity';
import { UserWireLimits } from './product-features/user-wire-limits.entity';
import { User } from './user.entity';
import { UserTimeAccessData } from './users.types';

type ApprovalListRequestModel = Partial<ApprovalListRequestModelDto>;

@Injectable()
export class UsersService {
    constructor(
        private readonly config: ConfigurationService,
        private readonly boClient: BoUsersClient,
        private readonly channelClient: ChannelUsersClient,
        private usersClient: UsersClient
    ) {}

    public async getCurrentUserTimeAccess(): Promise<UserTimeAccessData> {
        if (this.config.app === AppType.BackOffice) {
            const { data } = await this.boClient.usersGetCurrentUserTimeAccess();
            return mapUserTimeAccess(data);
        }

        const { data } = await this.channelClient.usersGetCurrentUserTimeAccess();

        return data.timeAccess as UserTimeAccessData;
    }

    public async getApprovers(
        request: ApprovalListRequestModelDto | null,
        params: RequestParams = {}
    ) {
        return this.usersClient.usersGetApprovers(request, params);
    }

    async getUserAccountAccess(id: number) {
        const { data } = await this.usersClient.usersGetUserAccountAccess(id);
        return new UserAccountAccess(data);
    }

    async getUserIpRestrictions(id: number) {
        const { data } = await this.usersClient.usersGetIpRestrictions(id);
        return new UserIpRestrictions(data);
    }

    public async getUserTimeAccess(id: number) {
        const { data } = await this.usersClient.usersGetUserTimeAccess(id);
        return new UserTimeAccess(data);
    }

    public async getUserProductEntitlements(id: number, product: Feature | '') {
        const { data } = await this.usersClient.usersGetUserProductEntitlements(id, {
            productFeatureType: product,
        });
        switch (product) {
            case Feature.IntegratedServicesEntitlements:
                return new IntegratedServicesEntitlements(data);
            case Feature.InformationReporting:
            case Feature.WireInternationalEntitlements:
            case Feature.WireDomesticEntitlements:
            case Feature.StopPayment:
            case Feature.ArpExceptions:
            case Feature.AchExceptions:
            case Feature.AchFilters:
            case Feature.TransferEntitlements:
            default:
                return data;
        }
    }

    // NOTE: Adding this to mirror channel, but in mobile each of these are their own view
    public async getPositivePayEntitlements(id: number) {
        const checkExceptionsPromise = this.getUserProductEntitlements(id, Feature.ArpExceptions);
        const achFiltersPromise = this.getUserProductEntitlements(id, Feature.AchFilters);
        const AchExceptionsPromise = this.getUserProductEntitlements(id, Feature.AchExceptions);
        const [checkExceptions, achFilters, achExceptions] = await Promise.all([
            checkExceptionsPromise,
            achFiltersPromise,
            AchExceptionsPromise,
        ]);
        return {
            checkExceptions,
            achFilters,
            achExceptions,
        };
    }

    public async getUserAchProductEntitlements(id: number) {
        const { data } = await this.usersClient.usersGetUserAchEntitlements(id);
        return new AchEntitlements(data);
    }

    public async getUserAchLimits(id: number) {
        const { data } = await this.usersClient.usersGetUserAchLimits(id);
        return new AchLimits(data);
    }

    public async getUserLimits(id: number, product: string) {
        const { data } = await this.usersClient.usersGetUserProductLimits(id, {
            productFeatureType: product,
        });
        if (product === 'InternalTransfer') {
            return new UserTransferLimits(data);
        }
        if (product === 'WireTransfer') {
            return new UserWireLimits(data);
        }
        return data;
    }

    async getUserReceivablesSettings(id: number) {
        const { data } = await this.usersClient.usersGetUserReceivablesSettings(id);
        return new UserReceivablesSettings(data);
    }

    async fetchCurrentUser() {
        return UserRequests.fetchCurrentUser();
    }

    async fetchApprovers(body: ApprovalListRequestModel) {
        return UserRequests.fetchApprovers(body);
    }

    async fetchAvailableApprovers(body: ApprovalListRequestModel) {
        return UserRequests.fetchAvailableApprovers(body);
    }

    async fetchAvailableAndCompletedApprovers(body: ApprovalListRequestModel) {
        return UserRequests.fetchAvailableAndCompletedApprovers(body);
    }

    async getUserDetails(id: number) {
        const userHeader = await this.getUserHeader(id);
        const userInformation = await this.getUserInformation(id);
        const userProductFeatures = await this.getUserProductFeatures(id);
        const userData = {
            ...userHeader,
            ...userInformation.user,
            pendingUser: { ...userInformation.pendingUser },
            userProductFeatures,
            id,
        };
        return new User(userData);
    }

    async getPendingApprovalUsers() {
        const usersList: UserDto[] = await UserRequests.getAllUsers();
        return usersList.filter(user => user.publishState === 'Pending');
    }

    async approveOrRejectUser(user: UserDto, action: string, comments: string) {
        return (await TmHttpClient.getInstance()).request(
            `users/${user.id}/${action}?comments=${comments}`,
            {
                method: 'PUT',
            }
        );
    }

    async searchUsers(filterPredicate: (user: UserDto) => boolean) {
        return UserRequests.searchUsers().then(result => {
            if (Array.isArray(result)) {
                return result.filter(user => filterPredicate(user));
            }
            return result;
        });
    }

    /** Deprecated */
    static async getCurrentUserTimeAccess(): Promise<UserTimeAccess2Dto> {
        return UserRequests.getCurrentUserTimeAccess();
    }

    async getUserHeader(id: number) {
        return UserRequests.getUserHeader(id);
    }

    async getUserInformation(id: number) {
        return UserRequests.getUserInformation(id);
    }

    async getUserProductFeatures(id: number) {
        return UserRequests.getUserProductFeatures(id);
    }

    async getUserProductFeatureDetail(id: number, featureName: string) {
        return UserRequests.getUserProductFeatureDetail(id, featureName);
    }

    public async saveUisUserEnrollmentDetail(
        authCode: string,
        institutionId: string,
        state: string
    ) {
        const response = await this.usersClient.usersSaveUisEnrollmentLinkAndLogin({
            authCode,
            fiId: institutionId,
            state,
        });

        return response.data;
    }

    public async getUisEnrollmentLink(
        institutionId: string,
        companyAlias: string,
        treasuryEnrollmentId: string,
        userAlias: string
    ) {
        const resp = await this.usersClient.usersGetUisEnrollmentLink({
            fiId: institutionId,
            companyAlias,
            treasuryEnrollmentId,
            userAlias,
        });

        return resp.data;
    }
}
