import { Observable, noOp } from '@treasury/utils';
import { IRealtimeService } from './realtime.service.types';

export const NullRealtimeService: IRealtimeService = {
    connected$: new Observable(),
    init: noOp,
    connect: () => Promise.resolve(),
    disconnect: noOp,
    subscribe: () => ({ unsubscribe: noOp }),
    publish: () => Promise.resolve(),
};
