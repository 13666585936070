import { InjectionToken } from '@jack-henry/frontend-utils/di';

export type HttpResponse = Blob | ArrayBuffer | Response | string;
export type HttpVerb = 'GET' | 'POST' | 'PUT' | 'DELETE';
/**
 * Fetch signature as implemented by web browsers (contrasted with Node's version).
 */
export type BrowserFetch = Window['fetch'];
export type FetchFn = typeof fetch;

export const FetchFnToken = new InjectionToken('FetchFunction', {
    value: window.fetch.bind(window),
});
