import { Injectable } from '@jack-henry/frontend-utils/di';
import { AccountClient, UserModelDto } from '@treasury/api/bo';
import { SecurityQuestionModelDto } from '@treasury/api/channel';
import { NavigationService } from '@treasury/core/navigation';
import { noOp } from '@treasury/utils';
import { UserSettings } from '../../../accounts';
import { StatusCode } from '../../../channel/services';
import { AuthenticationService } from '../../../services/authentication';
import { EntitlementsService } from '../entitlements';
import { State, StateName, transitions } from './constants';

@Injectable()
export class BoAccountService {
    constructor(
        private readonly client: AccountClient,
        private readonly authService: AuthenticationService,
        private readonly navigationService: NavigationService
    ) {}

    initialState!: State;

    currentStateName: StateName = StateName.LOGIN;

    public initiateAuthentication(state: State) {
        this.initialState = Object.freeze({ ...state });
        this.currentStateName = StateName.LOGIN;
        return this.getNextStep();
    }

    /**
     * Retrieves the next step based on the current state and transitions.
     * @returns The status code of the next step.
     * @throws Error if too many transitions are found.
     */
    public async getNextStep() {
        const matchedTransitions = transitions.filter(
            transition => transition.currentStateName === this.currentStateName
        );

        if (matchedTransitions.length > 1) {
            throw new Error('Too many transitions found.');
        }

        if (matchedTransitions.length !== 1) {
            return this.mapStateToStatusCode(StateName.LOGIN);
        }

        const matchedTransition = matchedTransitions[0];

        const matchedStates = matchedTransition.nextStates.filter(
            state =>
                this.matchValue(
                    this.initialState.userCredentialStatus,
                    state.userCredentialStatus
                ) &&
                this.matchValue(this.initialState.rsaStatus, state.rsaStatus) &&
                this.matchValue(this.initialState.secureTokenStatus, state.secureTokenStatus)
        );

        if (matchedStates.length <= 0) {
            return this.mapStateToStatusCode(StateName.LOGIN);
        }

        // States have order, so the first matching state takes precedence
        const matchedState = matchedStates[0];

        if (matchedState.loginComplete) {
            await this.completeLogin();
            return StatusCode.Allow;
        }

        // Need to update the current state before visiting the next state so
        // it wont fail validation.
        this.currentStateName = matchedState.nextStateName!;

        return this.mapStateToStatusCode(this.currentStateName);
    }

    /**
     * Checks if the initial value matches the given value.
     * @param initialValue - The initial value to compare.
     * @param value - The value to compare against the initial value.
     * @returns A boolean indicating whether the initial value matches the given value.
     */
    private matchValue(initialValue: unknown, value: unknown): boolean {
        if (value == null) {
            return true;
        }

        if (Array.isArray(value)) {
            return value.some((item: unknown) => initialValue === item);
        }

        return initialValue === value;
    }

    /**
     * Maps a state name to a corresponding status code.
     *
     * @param state - The state name to map.
     * @returns The corresponding status code.
     * @throws Error if the state name is unknown.
     */
    private mapStateToStatusCode(state: StateName) {
        switch (state) {
            case StateName.LOGIN:
                return StatusCode.Login;
            case StateName.ANSWER_SECURITY_QUESTIONS:
                return StatusCode.ChallengeQuestions;
            case StateName.AUTHENTICATE_SECURE_TOKEN:
                return StatusCode.Challenge;
            case StateName.REGISTER_SECURE_TOKEN:
                return StatusCode.RegisterSecureToken;
            case StateName.CREATE_SECURITY_QUESTIONS:
                return StatusCode.Enrollment;
            case StateName.USER_VERIFICATION:
                return StatusCode.UserVerification;
            default:
                throw new Error('Unknown state.');
        }
    }

    public async verifySecurityQuestions(securityQuestions: SecurityQuestionModelDto[]) {
        const response = await this.client.accountVerifySecurityQuestions(securityQuestions);
        return response.data;
    }

    private async completeLogin() {
        await this.authService.authenticate();
        this.currentStateName = StateName.LOGIN;
        await EntitlementsService.reset();
        await EntitlementsService.getEntitlements();
        const hasSwitchFI = await EntitlementsService.hasEntitlement('Switch FI');
        if (hasSwitchFI) {
            this.navigationService.navigate('institution-search');
        } else {
            this.navigationService.navigate('company-search');
        }
    }

    /**
     * Retrieves the user settings from the account service.
     * @returns The user settings object.
     */
    public async getUserSettings() {
        const { data } = await this.client.accountSettings();

        // normalize data to fulfill client-side shape
        const settings: UserSettings = {
            ...data,
            areNotificationsEnabled: false,
        };

        return settings;
    }

    public async login(fiId: string, loginId: string, password: string) {
        const credentials = {
            fiID: fiId,
            loginID: loginId,
            password,
            riskReceiptId: '',
            useRefreshTokens: false,
        } as unknown as UserModelDto;

        const result = await this.client.accountLogin(credentials);
        return result.data;
    }

    public logout(reason: string) {
        this.authService.invalidate();
        return this.client.accountLogout({ reason }).then(noOp);
    }
}
