ApproveOrRejectBeneficiaryController.$inject = ['$scope', '$modalInstance', 'type'];

export default function ApproveOrRejectBeneficiaryController($scope, $modalInstance, type) {
    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.type = type;

    // this will update the company
    $scope.save = function () {
        $modalInstance.close($scope.comments);
    };

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };
}
