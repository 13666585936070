import { filterGrid } from "../../../filters/gridFilterHelper.js";

AccountsListController.$inject = [
    '$scope',
    '$state',
    '$filter',
    '$uibModal',
    'accountTransactionsService',
    'downloadPageId',
    'spinnerService',
];

export default function AccountsListController(
    $scope,
    $state,
    $filter,
    $uibModal,
    accountTransactionsService,
    downloadPageId,
    spinnerService
) {
    $scope.deposits = 0;
    $scope.depositsList = [];
    $scope.timeDeposits = 0;
    $scope.timeDepositsList = [];
    $scope.loans = 0;
    $scope.loansList = [];
    $scope.collapseObj = {
        depositsCollapsed: false,
        timeDepositsCollapsed: false,
        loansCollapsed: false,
    };
    $scope.hasAccessPermissions = hasAccessPermissions;
    $scope.downloadPageId = downloadPageId.AccountList;
    $scope.disableDownload = true;

    $scope.isWidthSmall = function (widthValue) {
        let offset = 351;
        if (widthValue === true) offset = 311;
        return offset;
    };

    $scope.viewAccountTransactions = function (account, type) {
        $state.go('accounts.transactions', { accountId: account.id, type });
    };

    $scope.calculateAmount = function (array, field) {
        let total = 0;
        angular.forEach(array, item => {
            total += item[field];
        });
        return total;
    };

    $scope.accountDownloadOptions = function (account, collectionId) {
        const collections = {
            loans: $scope.loansList,
            deposits: $scope.depositsList,
            timeDeposits: $scope.timeDepositsList,
        };
        $uibModal.open({
            template: require('../views/accountDownloadModalView.html'),
            size: 'sm',
            controller: 'AccountDownloadController',
            backdrop: 'static',
            resolve: {
                account() {
                    return account;
                },
                collection() {
                    return collections[collectionId];
                },
            },
        });
    };

    $scope.loadAccountsCoreData = function (accounts, includeCollectedBalance) {
        if (accounts) {
            getAccountsCoreData(accounts, includeCollectedBalance);
        }
    };

    function loadDeposits() {
        accountTransactionsService.getAccountSummary('Deposit').then(response => {
            $scope.depositsList = response;
            downloadAvailable();
        });
    }

    function hasAccessPermissions(account, permissionType) {
        let result = false;
        if (account.status === 'Active') {
            angular.forEach(account.permissions, permission => {
                if (permission.permission === permissionType) {
                    result = true;
                }
            });
        }
        return result;
    }

    $scope.$watch(
        'depositsList',
        newValue => {
            if (newValue !== null && newValue !== undefined) {
                let total = 0;
                angular.forEach(newValue, item => {
                    total += item.currentBalance;
                });
                $scope.deposits = total;
            }
            $scope.filteredDepositsList = $filter('filter')(
                $scope.depositsList,
                $scope.depositFilterText
            );
        },
        true
    );

    $scope.$watch('depositFilterText', filterDepositsList);

    function filterDepositsList(filterText) {
        const depositsList = $scope.depositsList;
        filterText = filterText || '';
        $scope.filteredDepositsList = $filter('filter')(depositsList, filterGrid(filterText, [
            'number',
            'name',
            'status',
            'currentBalance',
            'collectedBalance',
            'availableBalance',
        ]))
    }

    function loadTimeDeposits() {
        accountTransactionsService.getAccountSummary('TimeDeposit').then(response => {
            $scope.timeDepositsList = response;
            downloadAvailable();
        });
    }

    $scope.$watch(
        'timeDepositsList',
        newValue => {
            if (newValue !== null && newValue !== undefined) {
                let total = 0;
                angular.forEach(newValue, item => {
                    total += item.currentBalance;
                });
                $scope.timeDeposits = total;
            }
            $scope.filteredTimeDepositsList = $filter('filter')(
                $scope.timeDepositsList,
                $scope.timeDepositFilterText
            );
        },
        true
    );

    $scope.$watch('timeDepositFilterText', filterTimeDepositsList);

    function filterTimeDepositsList(filterText) {
        const timeDepositsList = $scope.timeDepositsList;
        filterText = filterText || '';
        $scope.filteredTimeDepositsList = $filter('filter')(timeDepositsList, filterGrid( filterText, [
            'number',
            'name',
            'status',
            'currentBalance',
        ]))
    }

    $scope.createTransfer = function (deposit, type) {
        if (deposit.type === 'Loan') {
            if (type === 'from') {
                $state.go('payables.create-loan-payment', { fromAccountId: deposit.id });
            }
            if (type === 'to') {
                $state.go('payables.create-loan-payment', { toAccountId: deposit.id });
            }
        } else {
            if (type === 'from') {
                $state.go('payables.transfers', { fromAccountId: deposit.id });
            }
            if (type === 'to') {
                $state.go('payables.transfers', { toAccountId: deposit.id });
            }
        }
    };

    function loadLoans() {
        accountTransactionsService.getAccountSummary('Loan').then(response => {
            $scope.loansList = response;
            downloadAvailable();
        });
    }

    function downloadAvailable() {
        if (
            $scope.loansList.length === 0 &&
            $scope.timeDepositsList.length === 0 &&
            $scope.depositsList.length === 0
        ) {
            $scope.disableDownload = true;
        } else {
            $scope.disableDownload = false;
        }
    }

    $scope.$watch(
        'loansList',
        newValue => {
            if (newValue !== null && newValue !== undefined) {
                let total = 0;
                angular.forEach(newValue, item => {
                    total += item.currentBalance;
                });
                $scope.loans = total;
            }
            $scope.filteredLoansList = $filter('filter')($scope.loansList, $scope.loansFilterText);
        },
        true
    );

    $scope.$watch('loansFilterText', filterLoansList);

    function filterLoansList(filterText) {
        const loansList = $scope.loansList;
        filterText = filterText || '';
        $scope.filteredLoansList = $filter('filter')(loansList, filterGrid(filterText, [
            'number',
            'name',
            'status',
            'currentBalance',
        ]))
    }

    $scope.refreshData = function (type) {
        if (type === 'deposits') {
            loadDeposits();
        } else if (type === 'timeDeposits') {
            loadTimeDeposits();
        } else if (type === 'loans') {
            loadLoans();
        }
    };

    function getAccountsCoreData(accounts, includeCollectedBalance) {
        const accountIds = [];
        angular.forEach(accounts, account => {
            if (!account.coreData) {
                accountIds.push(account.id);
                account.coreData = true;
            }
        });

        while (accountIds.length > 0) {
            accountTransactionsService
                .getAccountsCoreData(accountIds.splice(0, 10), includeCollectedBalance, true)
                .then(response => {
                    mapBalancesandPermissions(accounts, response);
                });
        }
    }

    function mapBalancesandPermissions(accounts, balances) {
        angular.forEach(accounts, account => {
            angular.forEach(balances, balance => {
                if (account.id === balance.id) {
                    account.collectedBalance = balance.collectedBalance;
                    account.permissions = balance.permissions;
                }
            });
        });
    }

    function init() {
        loadDeposits();
        loadTimeDeposits();
        loadLoans();
    }

    init();
}
