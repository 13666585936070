import { Record } from '@treasury/FDL';
import { css, html, LitElement, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
// eslint-disable-next-line import/extensions
import { ListeningElementMixin } from './listening-element';
import './progress/omega-progress.js';
import columnConfig from './table/column-config.js';
import ColumnFactory from './table/column-factory.js';
import './table/omega-pagination.js';
import './table/table-filters.js';

const columnFactory = new ColumnFactory(columnConfig);

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

const ListeningElement = ListeningElementMixin(LitElement);

class OmegaTable extends ListeningElement {
    static get properties() {
        return {
            columns: Array,
            columnDefinitions: Array,
            /**
             * Renders a detail section below the current row when it is expanded.
             * A detail column must be present or `autoExpandDetailRows` set to `true`
             * for detail rows to be rendered.
             */
            detailRowTemplateFunction: Function,
            detailRows: Array,
            loading: Boolean,
            recordset: Object,
            filters: Array,
            indicateFiltering: Boolean,
            hideDetailColumn: Boolean,
            itemLabel: Object,
            autoExpandDetailRows: Boolean,
            /**
             * Invoke a function on render against each record that returns
             * a color string to be applied to the row's border.
             */
            borderColorFn: Function,
            /**
             * Invoke a function on render against reach record
             * to determine if a row should be displayed in a disabled state.
             */
            disabledWhen: Function,
            displayHeader: Boolean,
            displayPagination: Boolean,
            /**
             * Whether or not a red asterisk should appear the top of columns
             * containing all required fields.
             */
            showRequiredIndicator: {
                type: Boolean,
            },
            rowsPerPageOptions: Array,
        };
    }

    constructor() {
        super();
        this.columns = [];
        this.columnDefinitions = [];
        this.detailRows = [];
        this.loading = false;
        this.hideDetailColumn = false;
        this.itemLabel = {
            singular: 'item',
            plural: 'items',
        };
        this.autoExpandDetailRows = false;
        /**
         * @type {(record:Record) => string | undefined}
         */
        this.borderColorFn = undefined;
        /**
         * @type {((record: Record) => boolean) | undefined} |
         */
        this.disabledWhen = undefined;
        this.displayHeader = true;
        this.displayPagination = true;
        this.showRequiredIndicator = false;
        this.rowsPerPageOptions = ROWS_PER_PAGE_OPTIONS;
        this.indicateFiltering = true;
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-table--basic',
        };
    }

    get hasGroupedColumns() {
        return this.columnDefinitions.filter(definition => definition.columns).length;
    }

    async firstUpdated() {
        if (!this.recordset) {
            throw new Error('Recordset required for <omega-table></omega-table>');
        }

        if (!this.columnDefinitions) {
            throw new Error('Column definitions required for <omega-table></omega-table>');
        }

        this.addEventListener('toggle-detail-row', this.toggleDetailRow);
        this.addEventListener('toggle-all-detail-row', this.toggleAllDetailRow);
        this.readColumns();
    }

    updated(props) {
        if (props.has('recordset') && this.recordset) {
            ['page-changed', 'updated', 'counts-changed'].forEach(listener =>
                this.listenTo(this.recordset, listener, () => {
                    this.requestUpdate();
                })
            );

            this.listenTo(this.recordset, 'loading', ({ detail }) => {
                this.loading = detail.loading;
            });
            this.listenTo(this.recordset, 'error', ({ detail }) => {
                this.dispatchEvent(new CustomEvent('error', { detail }));
            });
        }

        if (props.has('columnDefinitions')) {
            this.readColumns();
        }
    }

    disconnectedCallback() {
        this.disposeColumns();

        super.disconnectedCallback();
    }

    async readColumns() {
        await false;
        const columnElements = [...this.shadowRoot.querySelector('slot').assignedNodes()].filter(
            node => node.nodeType === Node.ELEMENT_NODE
        );

        if (columnElements.length > 0) {
            this.columns = columnElements
                .map(node => columnFactory.createColumnFromNodeAndRecordset(node, this.recordset))
                .filter(column => column !== null);
        } else {
            this.columns = columnFactory.createColumnsFromColumnDefinitions(
                this.columnDefinitions,
                this.recordset
            );
        }
        if (this.hideDetailColumn) {
            this.columns.splice(
                this.columns.findIndex(i => i === 'DetailColumn'),
                1
            );
        }
    }

    disposeColumns() {
        for (const column of this.columns) {
            column.dispose();
        }
    }

    toggleDetailRow(e) {
        const { record } = e.detail;
        record.isExpanded = !record.isExpanded;
        this.requestUpdate();
    }

    toggleAllDetailRow(e) {
        this.recordset.allRecords.forEach(record => {
            record.isExpanded = e.detail.value; // eslint-disable-line no-param-reassign
        });
        this.recordset.sortedFilteredRecords.forEach(record => {
            record.isExpanded = e.detail.value; // eslint-disable-line no-param-reassign
        });
        this.requestUpdate();
    }

    /**
     * @param { Record } record
     */
    getRowStyles(record) {
        const rowStyles = {};

        if (typeof this.borderColorFn === 'function') {
            const customBorderColor = this.borderColorFn(record);
            if (customBorderColor) {
                rowStyles['border-color'] = customBorderColor;
                rowStyles['border-width'] = '1px';
                rowStyles['border-style'] = 'solid';
            }
        }

        return rowStyles;
    }

    renderCellsForRow(record, rowIndex) {
        return this.columns.map(column => column.renderTd(record, rowIndex));
    }

    /**
     *
     * @param { Record } record
     * @param { number } rowIndex
     * @param { boolean } isExpanded
     */
    renderBodyTr(record, rowIndex, isExpanded) {
        if (this.autoExpandDetailRows) {
            isExpanded = true;
        }

        const rowStyles = this.getRowStyles(record);
        const isDisabled =
            typeof this.disabledWhen === 'function' ? this.disabledWhen(record) : false;
        const mainRowStyles = isExpanded
            ? {
                  ...rowStyles,
                  'border-bottom': 'none',
              }
            : rowStyles;

        const data = html`
            <tr
                style="${styleMap(mainRowStyles)}"
                class=${classMap({
                    disabled: isDisabled,
                    even: rowIndex % 2 === 1,
                    odd: rowIndex % 2 === 0,
                    'omega-table-selected-row': isExpanded && !this.autoExpandDetailRows,
                })}
            >
                ${this.renderCellsForRow(record, rowIndex)}
            </tr>
        `;

        const detailRowStyles = isExpanded
            ? {
                  ...rowStyles,
                  'border-top': 'none',
              }
            : {};

        const detail =
            this.detailRowTemplateFunction && isExpanded
                ? html`${this.detailRowTemplateFunction(record, () => {
                      record.isExpanded = false; // eslint-disable-line no-param-reassign
                      this.requestUpdate();
                  })}`
                : nothing;

        const detailRow = html`<tr
            style="${styleMap(detailRowStyles)}"
            class="${classMap({
                disabled: isDisabled,
                'omega-table-detail-row': true,
                visible: isExpanded,
            })}"
            data-row="row-${rowIndex}"
        >
            <td colspan=${this.columns.length}>${detail}</td>
        </tr>`;

        return html`${data}${detailRow}`;
    }

    renderBodyRows() {
        if (!this.recordset || this.loading) return nothing;
        return this.recordset.currentPage.map((record, rowIndex) =>
            this.renderBodyTr(record, rowIndex, record.isExpanded)
        );
    }

    renderTableFooter() {
        const hasSummaryColumn = this.columns.some(column => column.summary);
        if (hasSummaryColumn) {
            const columnsSummary = this.columns.map(column =>
                column.renderSummary(this.recordset.currentPage)
            );
            return html`<tfoot>
                ${columnsSummary}
            </tfoot>`;
        }
        return nothing;
    }

    renderTh(column) {
        if (!this.displayHeader) {
            return nothing;
        }

        const { recordset, showRequiredIndicator } = this;
        const record = this.recordset.currentPage[0];
        return column.renderTh({
            getFieldType(field) {
                return recordset.getFieldType(field);
            },
            getColumnSortDirection() {
                return column.field === recordset.sortColumns[0]?.field
                    ? recordset.sortColumns[0].sort
                    : '';
            },
            sort(c) {
                recordset.sort(c);
                recordset.pageNumber = 1;
            },
            get visibleRecords() {
                return recordset.currentPage;
            },
            record,
            showRequiredIndicator,
        });
    }

    renderGroupTh() {
        if (!this.columns.length) return nothing;
        if (!this.hasGroupedColumns) return nothing;
        const columnGroupHeaders = this.columnDefinitions.map((definition, idx) =>
            this.columns[idx].renderGroupTh(definition)
        );
        return html`<tr id="grouped-column-row">
            ${columnGroupHeaders}
        </tr>`;
    }

    renderDetailRowTemplate(record, close) {
        return this.detailRowTemplateFunction(record, close);
    }

    renderLoadingState() {
        if (this.loading) {
            const rows = this.recordset.pageSize || 10;
            return [...new Array(rows)].fill('').map(idx => this.renderBodyTr(null, idx, false));
        }

        return nothing;
    }

    renderPagination() {
        return this.displayPagination
            ? html`<omega-pagination
                  part="pagination"
                  class="omega-no-print"
                  .itemLabel=${this.itemLabel}
                  .recordset=${this.recordset}
                  .rowsPerPageOptions=${this.rowsPerPageOptions}
                  .indicateFiltering=${this.indicateFiltering}
              ></omega-pagination>`
            : nothing;
    }

    render() {
        // Ignore the "no-unclosed-tag" warning: https://github.com/runem/lit-analyzer/issues/161
        this.disposeColumns();

        return html`
            <slot> </slot>

            <table>
                <colgroup>
                    ${this.columns.map(column => column.renderCol())}
                </colgroup>
                <thead>
                    ${this.renderGroupTh()}
                    <tr>
                        ${this.columns.map(col => this.renderTh(col))}
                    </tr>
                </thead>
                <tbody>
                    ${this.renderLoadingState()} ${this.renderBodyRows()}
                </tbody>
                ${this.renderTableFooter()}
            </table>
            ${this.renderPagination()}
        `;
    }

    static get styles() {
        return css`
            :host {
                display: flex;
                flex-direction: column;
                flex-grow: var(--omega-table-flex-grow, initial);
                width: 100%;
                overflow: var(--omega-table-overflow, initial);
                position: relative;
                --omega-checkbox-box-shadow: none;
                background: var(--omega-table-background, transparent);
                --omega-field-control-font-weight: normal;
            }
            * {
                box-sizing: border-box;
            }

            table {
                width: 100%;
                margin: 0;
                border-collapse: collapse;
            }

            th,
            thead td {
                position: sticky;
                top: 0;
                z-index: 1;
                height: 45px;
                padding: 0 10px;
                border-width: 0;
                border-style: solid;
                font-size: var(--omega-label);
                background-color: #e6e6e6;
                box-shadow: inset 0 -1px #ccc;
                color: var(--omega-text-secondary);
                font-weight: 400;
                text-align: left;
                white-space: nowrap;
            }

            th {
                font-size: 12px;
                font-weight: 500;
                white-space: nowrap;
            }

            th.sortable {
                cursor: pointer;
            }

            th.sortable[aria-sort='ascending'] .sort-icon {
                display: inline;
                transform: rotate(180deg);
                top: 0;
            }

            th.sortable[aria-sort='descending'] .sort-icon {
                display: inline;
                transform: rotate(0deg);
            }

            th.sortable[aria-sort='descending'] .unsorted-icon,
            th.sortable[aria-sort='ascending'] .unsorted-icon {
                display: none;
            }

            th.sortable .sort-icon {
                transform: rotate(0deg);
                margin-left: 3px;
                display: none;
                width: 8px;
                height: 17px;
                fill: var(--omega-text-tertiary);
                position: relative;
                top: 5px;
                transform-origin: center;
            }

            th.sortable .unsorted-icon {
                width: 17px;
                height: 17px;
                fill: var(--omega-text-tertiary);
                position: relative;
            }

            tr.even {
                background-color: #f5f5f5;
                border-top: 1px solid #f0f0f0;
                border-bottom: 1px solid #f0f0f0;
            }

            tr.odd {
                background-color: var(--omega-primary-foreground, #ffffff);
            }

            td {
                padding: 12px 10px;
                font-size: var(--omega-p);
                color: #3c3c3c;
            }

            td omega-field {
                padding: 0;
            }

            .numeric {
                text-align: right;
            }

            .report-numeric {
                text-align: left;
            }

            .count {
                text-align: center;
            }

            .icon-column {
                text-align: center;
                padding: 5px;
                max-width: var(--icon-column-max-width);
                min-width: var(--icon-column-min-width);
            }

            td.editable-cell {
                white-space: normal;
            }

            td input[type='radio'] {
                accent-color: var(--brand-color);
            }

            td span.link-column {
                cursor: pointer;
                text-decoration: underline;
                color: var(--omega-primary);
            }

            tr.omega-table-selected-row {
                background-color: #f7fbfe;
            }

            tr.omega-table-detail-row {
                background-color: #fff;
            }

            tr.omega-table-detail-row.visible {
                background-color: #fff;
                border: 1px solid #dde7ef;
            }

            tr.omega-table-detail-row td {
                line-height: 0;
                opacity: 0;
                padding: 0;
                transition: all 100ms;
            }

            tr.omega-table-detail-row.visible td {
                line-height: 1em;
                padding: 0;
                opacity: 1;
            }

            #grouped-column-row {
                background-color: #e6e6e6;
                border-top: 1px solid #cccccc;
            }

            #grouped-column-row th {
                height: 20px;
            }

            #grouped-column-header {
                text-align: center;
                background-color: #e8edff;
                /* border-right: 1px solid #CCCCCC; */
            }

            .omega-table-detail-header {
                height: 40px;
                padding: 10px 17px 10px 17px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #dde7ef;
            }
            .omega-table-detail-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .omega-table-detail-content-column {
                flex: 1;
                padding: 10px;
                min-height: 150px;
            }
            .omega-table-detail-content-column:not(:last-child) {
                padding-right: 10px;
                border-right: 1px solid #dde7ef;
            }

            .omega-table-detail-row-title {
                padding-top: 0px;
                margin-top: 0px;
                margin-right: 10px;
                font-weight: 600;
                font-size: 14px;
                color: #333333;
            }

            .omega-table-form-group {
                display: flex;
            }
            .omega-table-form-group:not(:last-child) {
                margin-bottom: 10px;
            }
            .omega-table-detail-row-label {
                font-size: 13px;
                color: #787878;
                margin-left: 15px;
                margin-right: 5px;
                flex: 0;
                white-space: nowrap;
                min-width: 140px;
            }
            .omega-table-detail-row-label.align-center {
                display: flex;
                align-items: center;
            }

            .omega-table-detail-row-value {
                flex: 1;
            }

            .detail {
                text-align: right;
            }

            .under {
                margin-left: 0px;
                padding-bottom: 15px;
            }

            tr.disabled {
                cursor: not-allowed;
            }

            tr.disabled td * {
                color: var(--omega-grayed-out);
            }

            .omega-table-detail-row-buttons {
                align-self: flex-end;
            }
            .table-loader-container {
                display: block;
                clear: both;
            }
            .table-loader {
                width: 75px;
                height: 75px;
                border-width: 7px;
            }
            omega-pagination {
                position: var(--pagination-position, sticky);
                bottom: var(--bottom-offset, 0px);
                left: 0;
            }
            tfoot th {
                position: sticky;
                bottom: calc(var(--bottom-offset, 0px) + 52px);
            }

            thead,
            tfoot {
                background-color: #e6e6e6;
            }

            @page {
                size: landscape;
            }

            @media print {
                * {
                    font-size: 12px;
                }
                th,
                thead td {
                    top: 0;
                }

                .omega-no-print {
                    display: none;
                }
                tfoot th {
                    position: relative;
                }
            }
        `;
    }
}

export const OmegaTableTagName = 'omega-table';

window.customElements.define(OmegaTableTagName, OmegaTable);
