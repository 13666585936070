import SecCodesService from './SecCodesSrvc.js';
import AccountService from './accountSrvc.js';
import AccountTransactionsService from './accountTransactionsSrvc.js';
import AchBankService from './achBankSrvc.js';
import AchBatchImportsService from './achBatchImportsSrvc.js';
import { AchBatchService } from './achBatchSrvc.js';
import AchCompaniesService from './achCompaniesSrvc.js';
import AchFileActivityService from './achFileActivitySrvc.js';
import { AchPaymentsService } from './achPaymentsSrvc.js';
import { AchRecipientsService } from './achRecipientsSrvc.js';
import AchSettingsService from './achSettingsSrvc.js';
import ArpService from './arpService.js';
import BankService from './bankSrvc.js';
import { BeneficiariesService } from './beneficiariesSrvc.js';
import BillPayService from './billPayService.js';
import CentrixSsoService from './centrixSsoSrvc.js';
import CheckImageService from './checkImageSrvc.js';
import CompanyAccountsService from './companyAccountsSrvc.js';
import CountriesService from './countriesSrvc.js';
import CurrenciesService from './currenciesSrvc.js';
import DashboardService from './dashboardSrvc.js';
import DateFilterService from './dateFilterSrvc.js';
import ElectronicDocumentsService from './electronicDocumentsSrvc.js';
import EntitlementsService from './entitlementsSrvc.js';
import FrequencyService from './frequencySrvc.js';
import { GenerateReportService } from './generateReportSrvc.js';
import HolidaysService from './holidaysSrvc.js';
import ImportLayoutService from './importLayoutSrvc.js';
import InformationReportsService from './informationReportsSrvc.js';
import { InternalTransfersService } from './internalTransfersSrvc.js';
import MessagingCenterService from './messagingCenterSrvc.js';
import MockDataService from './mockDataSrvc.js';
import NavigationService from './navigationSrvc.js';
import NotificationConfigurationService from './notificationConfigurationSrvc.js';
import NotificationsService from './notificationsSrvc.js';
import PaymentPendingApprovalProductsService from './paymentPendingApprovalProductsService.js';
import PrintService from './printSrvc.js';
import RemoteDepositCaptureSsoService from './remoteDepositCaptureSsoSrvc.js';
import ResearchTransactionsService from './researchTransactionSrvc.js';
import SearchFilterService from './searchFilterSrvc.js';
import SecurityService from './securitySrvc.js';
import StateChangeService from './stateChangeSrvc.js';
import StatesService from './statesSrvc.js';
import { StpService } from './stpService.js';
import TaxesService from './taxesSrvc.js';
import TermsAndConditionsService from './termsAndConditionsSrvc.js';
import TranGroupCodeService from './tranCodeGrpSrvc.js';
import { UsersService } from './usersSrvc.js';
import UtilityService from './utilitySrvc.js';
import WausauStatementsService from './wausauStatementsSrvc.js';
import WireCompaniesService from './wireCompaniesSrvc.js';
import { WireService } from './wireService.js';

export const servicesModule = angular
    .module('channel.services', [])
    .factory('printService', PrintService)
    .factory('remoteDepositCaptureSsoService', RemoteDepositCaptureSsoService)
    .factory('arpService', ArpService)
    .factory('researchTransactionsService', ResearchTransactionsService)
    .factory('bankService', BankService)
    .factory('securityService', SecurityService)
    .factory('beneficiariesService', BeneficiariesService)
    .factory('statesService', StatesService)
    .factory('stpService', StpService)
    .factory('centrixSsoService', CentrixSsoService)
    .factory('billPayService', BillPayService)
    .factory('checkImageService', CheckImageService)
    .factory('stateChangeService', StateChangeService)
    .factory('searchFilterService', SearchFilterService)
    .factory('companyAccountsService', CompanyAccountsService)
    .factory('taxesService', TaxesService)
    .factory('countriesService', CountriesService)
    .factory('termsAndConditionsService', TermsAndConditionsService)
    .factory('tranGroupCodeService', TranGroupCodeService)
    .factory('dashboardService', DashboardService)
    .factory('usersService', UsersService)
    .factory('secCodesService', SecCodesService)
    .factory('currenciesService', CurrenciesService)
    .factory('utilityService', UtilityService)
    .factory('accountService', AccountService)
    .factory('wireCompaniesService', WireCompaniesService)
    .factory('wausauStatementsService', WausauStatementsService)
    .factory('dateFilterService', DateFilterService)
    .factory('entitlementsService', EntitlementsService)
    .factory('accountTransactionsService', AccountTransactionsService)
    .factory('wireService', WireService)
    .factory('generateReportService', GenerateReportService)
    .factory('achBankService', AchBankService)
    .factory('achBatchImportsService', AchBatchImportsService)
    .factory('holidaysService', HolidaysService)
    .factory('electronicDocumentsService', ElectronicDocumentsService)
    .factory('achBatchService', AchBatchService)
    .factory('frequencyService', FrequencyService)
    .factory('informationReportsService', InformationReportsService)
    .factory('achPaymentsService', AchPaymentsService)
    .factory('importLayoutService', ImportLayoutService)
    .factory('internalTransfersService', InternalTransfersService)
    .factory('achCompaniesService', AchCompaniesService)
    .factory('achFileActivityService', AchFileActivityService)
    .factory('messagingCenterService', MessagingCenterService)
    .factory('achRecipientsService', AchRecipientsService)
    .factory('achSettingsService', AchSettingsService)
    .factory('notificationConfigurationsService', NotificationConfigurationService)
    .factory('notificationsService', NotificationsService)
    .factory('mockDataService', MockDataService)
    .factory('navigationService', NavigationService)
    .factory('paymentPendingApprovalProductsService', PaymentPendingApprovalProductsService);
