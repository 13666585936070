ChangeStatusDialogController.$inject = ['$scope', '$modalInstance', 'type'];

export default function ChangeStatusDialogController($scope, $modalInstance, type) {
    // this will dismiss the window.

    $scope.cancel = cancel;
    $scope.save = save;
    $scope.setForm = setForm;
    $scope.type = type;

    function cancel() {
        $modalInstance.dismiss();
    }

    // this will update the company
    function save() {
        $modalInstance.close($scope.comments);
    }

    // used to set the form instance.
    function setForm(form) {
        $scope.form = form;
    }
}
