BeneficiaryListController.$inject = [
    '$scope',
    'beneficiariesService',
    'entitlementsService',
    '$state',
    '$stateParams',
    '$modal',
    'modalService',
    'countriesService',
    'downloadPageId',
    'securityService',
    '$filter',
    'wireCompaniesService',
    'regexConstants',
];

export default function BeneficiaryListController(
    $scope,
    beneficiariesService,
    entitlementsService,
    $state,
    $stateParams,
    $modal,
    modalService,
    countriesService,
    downloadPageId,
    securityService,
    $filter,
    wireCompaniesService,
    regexConstants
) {
    const canCreateDomesticBeneficiary = entitlementsService.hasEntitlement(
        'Wire, Domestic, Create Beneficiary'
    );
    const canCreateInternationalBeneficiary =
        entitlementsService.hasEntitlement('Wire, International, Create Beneficiary') ||
        entitlementsService.hasEntitlement('Wire, Fx, Create Beneficiary');
    $scope.canCreateDomesticWire = entitlementsService.hasEntitlement(
        'Wire, Domestic, Create Wire'
    );
    $scope.canCreateInternationalWire = entitlementsService.hasEntitlement(
        'Wire, International, Create Wire'
    );
    $scope.canCreateFxWire = entitlementsService.hasEntitlement('Wire Transfer DLI, Create Wire');
    $scope.downloadPageId = downloadPageId.BeneficiaryList;
    $scope.beneficiaries = null;
    $scope.multipleWire = $stateParams.multipleWire;
    $scope.searchParameters = {};
    $scope.filter = {};
    $scope.updatedList = [];
    $scope.search = search;
    $scope.selectedList = [];
    $scope.filterObject = { requireEntitlement: true };
    $scope.options = ['All', 'Pending Approval', 'Rejected', 'Ready'];
    $scope.dateOptions = ['Specific Date', 'Date Range'];
    $scope.toggleAll = false;
    $scope.disableDownload = true;

    $scope.checkApprovedOrRejectedState = checkApprovedOrRejectedState;
    $scope.initiatePayment = initiatePayment;
    $scope.initiateBatchPayment = initiateBatchPayment;
    $scope.displayCreateBeneficiaryButton = displayCreateBeneficiaryButton;
    $scope.createBeneficiary = createBeneficiary;
    $scope.hasAccessPermissions = hasAccessPermissions;
    $scope.showCurrencyType = true;
    $scope.print = print;
    $scope.remove = remove;
    $scope.reset = reset;
    $scope.disableSelectOrDeselectAllBeneficiaries = disableSelectOrDeselectAllBeneficiaries;
    $scope.selectOrDeselectAllBeneficiaries = selectOrDeselectAllBeneficiaries;
    $scope.showBeneficiaryDetailDialog = showBeneficiaryDetailDialog;
    $scope.updateList = updateList;
    $scope.disableCheckbox = disableCheckbox;
    $scope.wireConfig = {};

    $scope.filteredBeneficiaries = {};
    $scope.filterSearch = filterSearch;
    $scope.searchObj = { text: null };
    $scope.disableFilter = disableFilter;
    $scope.alphaNumericRegex = regexConstants.AlphaNumericRegex;

    function disableSelectOrDeselectAllBeneficiaries() {
        for (let i = 0; i < $scope.filteredBeneficiaries.length; i++) {
            if (!disableCheckbox($scope.filteredBeneficiaries[i])) {
                return false;
            }
        }
        return true;
    }

    function disableCheckbox(beneficiary) {
        let disable = false;
        if (beneficiary.status !== 'Ready') {
            disable = true;
        } else if (beneficiary.isInternational) {
            if (beneficiary.isForeignCurrency) {
                disable = true;
            } else {
                // Can user create International (USD) or DLI Wire (USD)?
                disable = !$scope.canCreateInternationalWire && !$scope.canCreateFxWire;
            }
        } else {
            disable = !$scope.canCreateDomesticWire;
        }
        return disable;
    }

    function setApprovalAttributes() {
        $scope.beneficiaries.map(bene => {
            bene.approvalAttributes = {
                approvalEntity: 'beneficiary',
                approvalCount: bene.completedApprovalCount,
                amount: bene.amount,
                createdBy: bene.createdBy,
                updatedBy: bene.updatedBy,
                productId: bene.id,
            };
            return bene;
        });
    }

    function filterSearch() {
        $scope.filteredBeneficiaries = $filter('filter')(
            $scope.beneficiaries,
            $scope.searchObj.text
        );
    }

    function disableFilter() {
        return $scope.beneficiaries ? $scope.beneficiaries.length <= 0 : true;
    }

    function hasAccessPermissions(beneficiary, permissionType) {
        let result = false;
        angular.forEach(beneficiary.permissions, permission => {
            if (permission.permission === permissionType) result = true;
        });
        return result;
    }

    function reset() {
        $scope.filterObject.beneficiaryType = 'Both';
        $scope.filterObject.status = 'All';
        $scope.filterObject.beneficiaryName = '';
        $scope.filterObject.accountNumber = '';
        $scope.filterObject.bankId = '';
        $scope.filterObject.bankName = '';
        $scope.filterObject.country = '';
        $scope.filterObject.createdDateType = 'Specific Date';
        $scope.filterObject.createdDateStart = '';
        $scope.filterObject.createdDateEnd = '';
        $scope.filterObject.createdDate = '';
    }

    function print() {
        window.print();
    }

    function getCountries() {
        countriesService.getAll().then(response => {
            $scope.countries = response;
        });
    }

    function selectOrDeselectAllBeneficiaries() {
        angular.forEach($scope.filteredBeneficiaries, beneficiary => {
            if (!disableCheckbox(beneficiary)) {
                beneficiary.isSelected = $scope.filter.selectAll;
                if ($scope.filter.selectAll === true) {
                    $scope.updatedList.push(beneficiary);
                } else {
                    $scope.updatedList = [];
                }
            }
        });
    }

    function remove(beneficiary) {
        if (beneficiary.isTemplateBeneficiary) {
            const modalInstance2 = $modal.open({
                template: require('../views/blockRemoveBeneficiaryDialogView.html'),
                size: 'sm',
                controller: 'BlockRemoveBeneficiaryController',
                backdrop: 'static',
            });
            modalInstance2.result.then(goToTemplates => {
                if (goToTemplates) {
                    $state.go('payables.wire.wire-template-list');
                }
            });
        } else {
            const modalInstance = $modal.open({
                template: require('../views/removeBeneficiaryDialogView.html'),
                size: 'lg',
                controller: 'RemoveBeneficiaryController',
                backdrop: 'static',
            });
            modalInstance.result.then(comments => {
                deleteBeneficiary(beneficiary, comments);
            });
        }
    }

    function deleteBeneficiary(beneficiary, comments) {
        let actionType;
        if (beneficiary.isForeignCurrency == true) {
            actionType = 'DeleteFxBeneficiary_ByName';
        } else {
            actionType = 'DeleteBeneficiary_ByName';
        }

        const deleteBeneficiaryModel = {
            beneficiaryId: beneficiary.id,
        };
        securityService
            .verifyUser(actionType, deleteBeneficiaryModel, () =>
                beneficiariesService.deleteBeneficiary(deleteBeneficiaryModel)
            )
            .then(response => {
                $scope.beneficiaries.splice($scope.beneficiaries.indexOf(beneficiary), 1);
                filterSearch();
            });
    }

    function createBeneficiary() {
        if (canCreateDomesticBeneficiary && canCreateInternationalBeneficiary)
            $state.go('payables.wire.create-beneficiary', { type: 'both' });
        else if (canCreateDomesticBeneficiary)
            $state.go('payables.wire.create-beneficiary', { type: 'domestic' });
        else if (canCreateInternationalBeneficiary)
            $state.go('payables.wire.create-beneficiary', { type: 'international' });
    }

    function loadBeneficiaries() {
        if ($scope.multipleWire) {
            $scope.filterObject.status = 'Ready';
        }
        search($scope.filterObject);
    }

    function search(filter) {
        beneficiariesService.getFilteredBeneficiariesList(filter).then(response => {
            if (response.length === 0) {
                $scope.sidebarContainerController.setCollapsed(false);
            } else {
                $scope.sidebarContainerController.setCollapsed(true);
            }
            $scope.beneficiaries = response;
            if ($scope.beneficiaries.length === 0) {
                $scope.disableDownload = true;
            } else {
                $scope.disableDownload = false;
            }
            setApprovalAttributes();
            $scope.searchObj.text = null;
            $scope.filterSearch($scope.searchObj.text);
            $scope.filter.selectAll = false;
        });
    }

    function displayCreateBeneficiaryButton() {
        return canCreateDomesticBeneficiary || canCreateInternationalBeneficiary;
    }

    function updateList() {
        let allSelected = true;
        angular.forEach($scope.beneficiaries, beneficiary => {
            if (
                !beneficiary.isSelected &&
                (beneficiary.isInternational
                    ? $scope.canCreateInternationalWire
                    : $scope.canCreateDomesticWire) &&
                beneficiary.status === 'Ready'
            ) {
                allSelected = false;
            }
        });
        if (allSelected) {
            $scope.filter.selectAll = true;
        } else {
            $scope.filter.selectAll = false;
        }
    }

    function checkApprovedOrRejectedState() {
        let result = true;
        angular.forEach($scope.beneficiaries, beneficiary => {
            if (beneficiary.isSelected) result = false;
        });
        return result;
    }

    function initiatePayment(beneficiary) {
        beneficiariesService.setSelectedBeneficiaries(beneficiary);
        $state.go('payables.wire.create-multiple-wire');
    }

    function initiateBatchPayment() {
        const beneficiaries = [];
        angular.forEach($scope.filteredBeneficiaries, beneficiary => {
            if (beneficiary.isSelected) {
                beneficiaries.push(beneficiary);
            }
        });

        beneficiariesService.setSelectedBeneficiaries(beneficiaries);
        $state.go('payables.wire.create-multiple-wire');
    }

    function showBeneficiaryDetailDialog(beneficiary) {
        const modalInstance = $modal.open({
            template: require('../views/beneficiaryDetailDialogView.html'),
            size: 'lg',
            controller: 'BeneficiaryDetailDialogController',
            backdrop: 'static',
            resolve: {
                beneficiary() {
                    return angular.copy(beneficiary);
                },
                showCurrencyType() {
                    return $scope.showCurrencyType;
                },
            },
        });
        modalInstance.result.then(response => {
            $scope.beneficiaries[$scope.beneficiaries.indexOf(beneficiary)] = angular.extend(
                response,
                {
                    approvalAttributes: {
                        approvalEntity: 'beneficiary',
                        approvalCount: response.completedApprovalCount,
                        amount: response.amount,
                        createdBy: response.createdBy,
                        updatedBy: response.updatedBy,
                        productId: response.id,
                    },
                }
            );
            filterSearch();
        });
    }

    function shouldShowCurrencyType() {
        return (
            $scope.wireConfig &&
            $scope.wireConfig.fiWireProductEnabled &&
            $scope.wireConfig.companyWireProductEnabled &&
            $scope.wireConfig.companyInternationalUsdEnabled
        );
    }

    function getWireConfiguration() {
        wireCompaniesService.getWireConfiguration().then(response => {
            $scope.wireConfig = response;
            $scope.showCurrencyType = shouldShowCurrencyType();
        });
    }

    function dliInternationalUsdIsEnabled() {
        return (
            $scope.wireConfig.fiDliProductEnabled &&
            $scope.wireConfig.dliCompanyProductEnabled &&
            $scope.wireConfig.dliCompanyInternationalUsdEnabled
        );
    }

    (function () {
        // init
        reset();
        getCountries();
        loadBeneficiaries();
        getWireConfiguration();
        $scope.currentDate = moment().format('MM/DD/YYYY');
    })();
}
