BeneficiarySelectionController.$inject = [
    '$scope',
    '$modalInstance',
    'type',
    'wireCompaniesService',
    'beneficiaries',
    'enableFreeFormEntry',
    'countriesService',
];
export default function BeneficiarySelectionController(
    $scope,
    $modalInstance,
    type,
    wireCompaniesService,
    beneficiaries,
    enableFreeFormEntry,
    countriesService
) {
    $scope.beneficiarySearchText = '';
    $scope.close = close;
    $scope.setForm = setForm;
    $scope.type = type;
    $scope.beneficiaries = beneficiaries;
    $scope.selectBeneficiary = selectBeneficiary;
    $scope.searchTable = searchTable;
    $scope.enterFreeFormBeneficiary = enterFreeFormBeneficiary;
    $scope.enableFreeFormEntry = enableFreeFormEntry;
    $scope.getCountry = getCountry;

    // this will dismiss the window.
    function close() {
        $modalInstance.dismiss();
    }

    function enterFreeFormBeneficiary() {
        $modalInstance.close({ enterFreeFormBeneficiary: true });
    }

    function selectBeneficiary(beneficiary) {
        $modalInstance.close(beneficiary);
    }

    // used to set the form instance.
    function setForm(form) {
        $scope.form = form;
    }

    function searchTable(beneficiary) {
        if ($.isEmptyObject($scope.beneficiarySearchText)) {
            return true;
        }
        if ($.isEmptyObject(beneficiary)) {
            return false;
        }
        return (
            (!$.isEmptyObject(beneficiary.name) &&
                beneficiary.name
                    .toLowerCase()
                    .indexOf($scope.beneficiarySearchText.toLowerCase()) != -1) ||
            (!$.isEmptyObject(beneficiary.accountNumber) &&
                beneficiary.accountNumber
                    .toLowerCase()
                    .indexOf($scope.beneficiarySearchText.toLowerCase()) != -1) ||
            (!$.isEmptyObject(beneficiary.bank) &&
                !$.isEmptyObject(beneficiary.bank.bankId) &&
                beneficiary.bank.bankId
                    .toLowerCase()
                    .indexOf($scope.beneficiarySearchText.toLowerCase()) != -1) ||
            (!$.isEmptyObject(beneficiary.bank) &&
                !$.isEmptyObject(beneficiary.bank.name) &&
                beneficiary.bank.name
                    .toLowerCase()
                    .indexOf($scope.beneficiarySearchText.toLowerCase()) != -1) ||
            (!$.isEmptyObject(beneficiary.country) &&
                beneficiary.country
                    .toLowerCase()
                    .indexOf($scope.beneficiarySearchText.toLowerCase()) != -1)
        );
    }

    function getCountry(countryCode) {
        let result = null;
        window.angular.forEach($scope.countries, country => {
            if (country.countryCode === countryCode) {
                result = country.countryName;
            }
        });
        return result;
    }

    (function () {
        countriesService.getAll().then(response => {
            $scope.countries = response;
        });
    })();
}
