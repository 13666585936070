/* eslint-disable no-use-before-define */
/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@jack-henry/frontend-utils/di';
import {
    createUniqueId,
    CustomEventCallback,
    delay,
    exists,
    getKeys,
    noOp,
    ObservationSource,
} from '@treasury/utils';
import { html, nothing, render, RootPart, TemplateResult } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import '../../components/omega-dialog';
import { OmegaDialog } from '../../components/omega-dialog';
import '../../components/omega-dialog-branded';
import {
    DialogButton,
    DialogButtonPosture,
    OmegaDialogExitReason,
    OmegaDialogHandle,
    OmegaDialogMetadata,
    OmegaDialogOptions,
    OmegaDialogOptionsAll,
    OmegaDialogResult,
    postureToTypeMap,
} from './omega-dialog.service.types';

const defaultOptions: OmegaDialogOptionsAll = Object.freeze({
    buttons: {
        [OmegaDialogExitReason.Confirm]: {
            label: 'OK',
            onClick: noOp,
            posture: DialogButtonPosture.Primary,
        },
        [OmegaDialogExitReason.Cancel]: {
            label: 'Cancel',
            onClick: noOp,
            posture: DialogButtonPosture.Secondary,
        },
        [OmegaDialogExitReason.ForceClose]: null,
    },
    host: window.document.body,
    renderButtons: true,
    preventClose: false,
    flexDirection: 'row',
    height: 100,
    heightUnits: '%',
    width: 100,
    widthUnits: '%',
});

const DIALOG_ELEM_ID_PREFIX = 'omega-dialog';
/**
 * Service for rendering arbitrary content into an `<omega-dialog>`.
 */
@Injectable()
export class OmegaDialogService {
    /**
     * Tracks state about any currently open dialogs.
     *
     * At present, the service only supports modal dialogs (i.e., one at a time).
     * Using a dictionary enables us to support multiple dialogs in the future when
     * an appropriate UX has been decided.
     */
    private dialogs: Record<string, OmegaDialogMetadata<OmegaDialogResult<any>>> = {};

    private get hasDialogs() {
        return Object.keys(this.dialogs).length > 0;
    }

    public open<Elem extends HTMLElement = HTMLElement, T = unknown>(
        content: string | TemplateResult,
        title: string,
        options: OmegaDialogOptions = {}
    ): OmegaDialogHandle<Elem, T> {
        if (this.hasDialogs) {
            throw new Error(
                'Cannot open more than one dialog at a time.  Close the existing dialog before opening another.'
            );
        }
        const allOptions = normalizeOptions(options);
        const { host: parent } = allOptions;
        const id = createUniqueId();
        const template = this.renderDialog(id, content, title, allOptions);
        const part = render(template, parent);
        const closedStream = new ObservationSource<OmegaDialogResult<T>>();

        this.dialogs[id] = {
            part,
            options: allOptions,
            listeners: [],
            closed: closedStream,
        };

        const element = this.getDialogContentElement<Elem>(id, parent);

        // listen for close events raised by the element inside the dialog
        // since it does not own its own buttons
        this.listenFor<OmegaDialogResult>(id, 'close', ({ detail }) => {
            const { reason, data } = detail;
            this.closeDialog(id, reason, data);
        });

        return {
            close: result => {
                const reason = result ? result.reason : OmegaDialogExitReason.ForceClose;
                return this.closeDialog(id, reason, result?.data);
            },
            listenFor: (eventName, listener) => this.listenFor(id, eventName, listener),
            closed: closedStream.toObservable().toPromise(),
            element,
        };
    }

    public closeAll() {
        getKeys(this.dialogs).forEach(id => {
            this.closeDialog(id, OmegaDialogExitReason.ForceClose);
        });
    }

    private getDialog(id: string) {
        if (!(id in this.dialogs)) {
            throw new Error(
                `Could not get dialog with id ${id}. It is not tracked by OmegaDialogService.`
            );
        }

        return this.dialogs[id];
    }

    private getDialogElement(id: string, parent: Element | DocumentFragment) {
        const elem = parent.querySelector(`#${DIALOG_ELEM_ID_PREFIX}-${id}`);
        if (!elem) {
            throw new Error(`Could not find DOM element for dialog with id ${id}.`);
        }

        return elem as OmegaDialog;
    }

    private getDialogContentElement<Elem extends HTMLElement>(
        id: string,
        parent: Element | DocumentFragment
    ) {
        const dialogElement = this.getDialogElement(id, parent);
        const contentContainer = dialogElement.querySelector('.dialog-content');

        if (!contentContainer) {
            throw new Error('Could not get dialog content element.');
        }

        if (!contentContainer.firstElementChild) {
            throw new Error(
                'Could not get dialog content element. Did you pass a template containing text with no parent element?'
            );
        }

        return contentContainer.firstElementChild as Elem;
    }

    private async closeDialog(id: string, reason: OmegaDialogExitReason, data?: unknown) {
        if (!(id in this.dialogs)) {
            return;
        }

        const { options, listeners, closed, part } = this.getDialog(id);
        delete this.dialogs[id];

        if (options.buttons) options.buttons[reason]?.onClick?.();

        listeners.forEach(removeListener => removeListener());
        this.disposePart(part);

        const elem = this.getDialogElement(id, options.host);
        elem.open = false;

        // allow time for animation
        await delay(500);

        elem.remove();

        closed.emit({
            reason,
            data,
        });
        closed.complete();
    }

    private listenFor<T>(id: string, eventName: string, listener: CustomEventCallback<T>) {
        const dialog = this.getDialog(id);
        const { options } = dialog;
        const elem = this.getDialogContentElement(id, options.host);
        (elem as EventTarget).addEventListener(eventName, listener as EventListener);

        dialog.listeners.push(() => elem.removeEventListener(eventName, listener as EventListener));
    }

    private disposePart(part: RootPart) {
        part.setConnected(false);
        const { parentNode, startNode, endNode } = part;

        let node = startNode;
        while (exists(node) && node !== endNode) {
            parentNode.removeChild(node);
            node = node.nextSibling;
        }

        if (endNode) {
            parentNode.removeChild(endNode);
        }

        // eslint-disable-next-line dot-notation
        delete (parentNode as any)['_$litPart$'];
    }

    private renderButtons(
        buttons: OmegaDialogOptionsAll['buttons'],
        id: string,
        options: OmegaDialogOptionsAll
    ) {
        const { preventClose, renderButtons } = options;

        if (!renderButtons) {
            return nothing;
        }

        const buttonElements: TemplateResult[] = [];
        const confirmBtn = buttons[OmegaDialogExitReason.Confirm];
        const cancelBtn = buttons[OmegaDialogExitReason.Cancel];

        if (exists(confirmBtn)) {
            buttonElements.push(
                html` <omega-button
                    slot="actions"
                    type=${postureToTypeMap[confirmBtn.posture]}
                    @click=${() => this.closeDialog(id, OmegaDialogExitReason.Confirm)}
                    >${confirmBtn.label}</omega-button
                >`
            );
        }

        if (exists(cancelBtn) && !preventClose) {
            buttonElements.push(
                html` <omega-button
                    slot="actions"
                    type=${postureToTypeMap[cancelBtn.posture]}
                    @click=${() => this.closeDialog(id, OmegaDialogExitReason.Cancel)}
                    >${cancelBtn.label}</omega-button
                >`
            );
        }

        return buttonElements;
    }

    private renderDialog(
        id: string,
        content: string | TemplateResult,
        title: string,
        options: OmegaDialogOptionsAll
    ) {
        const {
            buttons,
            preventClose,
            logoSource,
            height,
            heightUnits,
            width,
            widthUnits,
            headerIcon,
        } = options;

        const contentTemplate = typeof content === 'string' ? html`<p>${content}</p>` : content;

        if (logoSource) {
            return html` <omega-dialog-branded
                hideCloseButton=${preventClose}
                id=${`${DIALOG_ELEM_ID_PREFIX}-${id}`}
                @close=${() => this.closeDialog(id, OmegaDialogExitReason.Cancel)}
                dialogTitle="${title}"
                logoSource=${logoSource}
                open
            >
                <div
                    class="dialog-content"
                    slot="content"
                    style=${`padding: 20px; min-width: 80%; max-width: 100%; width: ${width}${widthUnits}
                    ; height: ${height}${heightUnits}; min-height: 80%;`}
                >
                    ${contentTemplate}
                </div>
                ${this.renderButtons(buttons, id, options)}
            </omega-dialog-branded>`;
        }

        return html` <omega-dialog
            hideCloseButton=${preventClose}
            id=${`${DIALOG_ELEM_ID_PREFIX}-${id}`}
            @close=${() => this.closeDialog(id, OmegaDialogExitReason.Cancel)}
            dialogTitle="${title}"
            headerIcon=${ifDefined(headerIcon)}
            open
        >
            <div
                class="dialog-content"
                slot="content"
                style=${`padding: 20px; min-width: 80%;  max-width: 100%; width: ${width}${widthUnits}; height: ${height}${heightUnits}; min-height: 80%;`}
            >
                ${contentTemplate}
            </div>
            ${this.renderButtons(buttons, id, options)}
        </omega-dialog>`;
    }
}

function normalizeOptions(options: OmegaDialogOptions): OmegaDialogOptionsAll {
    const mergedButtons = getKeys(defaultOptions.buttons).reduce(
        (buttons, key) => {
            const defaultButton = defaultOptions.buttons?.[key];
            const button = options.buttons?.[key];
            buttons[key] =
                // support explicitly removing buttons with null value
                button === null ? null : ({ ...defaultButton, ...button } as DialogButton);
            return buttons;
        },
        {} as OmegaDialogOptionsAll['buttons']
    );

    if (options.height && !options.heightUnits) {
        options.heightUnits = 'px';
    }

    if (options.width && !options.widthUnits) {
        options.widthUnits = 'px';
    }

    return {
        ...defaultOptions,
        ...options,
        ...{
            // merge nested properties this way until a deep merge helper exists
            buttons: mergedButtons,
        },
    };
}
