BlockRemoveBeneficiaryController.$inject = ['$scope', '$modalInstance', 'entitlementsService'];

export default function BlockRemoveBeneficiaryController(
    $scope,
    $modalInstance,
    entitlementsService
) {
    $scope.canViewWireTemplate =
        entitlementsService.hasEntitlement('Wire, Domestic, Create Wire Template') ||
        entitlementsService.hasEntitlement('Edit Wire Template') ||
        entitlementsService.hasEntitlement('Approve Wire Template') ||
        entitlementsService.hasEntitlement('Delete Wire Template') ||
        entitlementsService.hasEntitlement('Wire, International, Create Wire Template') ||
        entitlementsService.hasEntitlement('Wire, International, Edit Wire Template') ||
        entitlementsService.hasEntitlement('Wire, International, Delete Wire Template') ||
        entitlementsService.hasEntitlement('Wire, International, Approve Wire Template');

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    // this will remove the beneficiary
    $scope.goToTemplates = function () {
        $modalInstance.close(true);
    };
}
