import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';
import { getChannelAPIPath } from '@treasury/core/http';

/**
 * @typedef { import('@treasury/api/channel').BeneficiaryModelDto } BeneficiaryModelDto 
 * @typedef { import('@treasury/api/channel').ApproveBeneficiaryModelDto} ApproveBeneficiaryModelDto
 */

/**
 * 
 * @param { ng.resource.IResourceService } $resource 
 * @param { ng.IHttpService } $http 
 * @param { import('@jack-henry/frontend-utils/di').DiContainer } TmDi 
 */
export function BeneficiariesService($resource, $http, TmDi) {
    const resourceUrl = `${getChannelAPIPath()}beneficiaries/`;
    let selectedBeneficiaries = [];

    return {
        getAll,
        get,
        create,
        getFilteredBeneficiariesList,
        batchApproveOrReject,
        deleteBeneficiary,
        approveOrReject,
        update,
        getBeneficiaries,
        setSelectedBeneficiaries,
        getSelectedBeneficiaries,
        isValidRoutingNumber,
        canUseThreeLineAddress,
        getCityStateAndPostalCode,
    };

    function getCityStateAndPostalCode(bank) {
        return `${(bank.city ? `${bank.city}, ` : '') + (bank.state ? bank.state : '')} ${
            bank.postalCode ? bank.postalCode : ''
        }${bank.postalCodeExtension ? `-${bank.postalCodeExtension}` : ''}`;
    }

    /**
     * @param { BeneficiaryModelDto } model 
     */
    function create(model) {
        const resource = $resource(resourceUrl);

        /**
         * @type { Promise<BeneficiaryModelDto>}
         */
        const p = resource.save(model).$promise;

        Promise.all([p, TmDi.getAsync(AnalyticsService)]).then(([response, analytics]) => {
            const { country, isInternational, currencyType, bank } = response;
            analytics.track(AnalyticsEvent.WireBeneficiaryCreated, {
                isInternational,
                currencyType,
                country,
                bankId: bank ? bank.id.toString() : undefined
            });
        });

        return p;
    }

    function update(model) {
        const resource = $resource(resourceUrl, {}, { update: { method: 'PUT' } });
        return resource.update(model).$promise;
    }

    /**
     * @param { 'Approve' | 'Reject' } type 
     * @param { ApproveBeneficiaryModelDto } approveBeneficiaryModel 
     * @returns { Promise<BeneficiaryModelDto> }
     */
    function approveOrReject(type, approveBeneficiaryModel) {
        if (type === 'Approve') {
            const resource = $resource(`${resourceUrl}approve`);
            const p = resource.save(approveBeneficiaryModel).$promise;

            Promise.all([p, TmDi.getAsync(AnalyticsService)]).then(([_, analytics]) => {
                const { beneficiaryId } = approveBeneficiaryModel;
                analytics.track(AnalyticsEvent.WireBeneficiaryApproved, {
                    id: beneficiaryId.toString()
                });
            });

            return p;
        }
        
        if (type === 'Reject') {
            return $resource(`${resourceUrl}reject`).save(approveBeneficiaryModel).$promise;
        }

        throw new Error(`Could not decision beneficiary; invalid action: ${type}`);
    }

    function getAll() {
        return $resource(resourceUrl).query().$promise;
    }

    function get(id) {
        return $resource(`${resourceUrl}:id`, { id }).get().$promise;
    }

    function deleteBeneficiary(deleteBeneficiaryModel) {
        return $http({
            method: 'DELETE',
            url: resourceUrl,
            headers: {
                'Content-Type': 'application/json',
            },
            data: deleteBeneficiaryModel,
        }).then(response => response.data);
    }

    function batchApproveOrReject(batch, type) {
        return $resource(`${resourceUrl}:id/batch?action=:action`, { id: 0, action: type }).save(
            batch
        ).$promise;
    }

    function getFilteredBeneficiariesList(filter) {
        return $http({ method: 'post', url: `${resourceUrl}search`, data: filter }).then(
            response => response.data
        );
    }

    function getBeneficiaries(type) {
        return $resource(`${resourceUrl}?type=:type`, { type }).query().$promise;
    }

    function setSelectedBeneficiaries(beneficiaries) {
        selectedBeneficiaries = [];
        if (Array.isArray(beneficiaries)) {
            selectedBeneficiaries = beneficiaries;
        } else {
            selectedBeneficiaries.push(beneficiaries);
        }
    }

    function getSelectedBeneficiaries() {
        return selectedBeneficiaries;
    }

    function isValidRoutingNumber(routingNumber) {
        if (!routingNumber || routingNumber.length !== 9) {
            return false;
        }

        let sum = 0;
        for (let i = 0; i < 3; i++) {
            sum += routingNumber[i * 3] * 3;
            sum += routingNumber[i * 3 + 1] * 7;
            sum += routingNumber[i * 3 + 2] * 1;
        }
        return !(sum % 10) && sum > 0;
    }

    /**
     * Indicates whether a beneficiary should have related addresses displayed in a 3-line address format.
     * When true: expect there can be AddressLine1, AddressLine2, AddressLine3.
     * When false: expect there can be AddressLine1 and AddressLine2, City, State, Zip/Postal Code (NO AddressLine3)
     * @param {any}beneficiary : beneficiary model
     * @returns bool result
     */

    function canUseThreeLineAddress(beneficiary) {
        return beneficiary && beneficiary.isInternational && !beneficiary.isForeignCurrency;
    }
}

BeneficiariesService.$inject = ['$resource', '$http', 'TmDi'];