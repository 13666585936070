import { formatDistance } from 'date-fns/formatDistance';

// eslint-disable-next-line @treasury/no-date
export const dateDistance = (date: Date, relativeDate: Date) =>
    formatDistance(date, relativeDate) as string;

export function isValidDate(d: Date) {
    return d.toString() !== 'Invalid Date';
}

export function tryParseDate(str: string) {
    const maybeValidDate = new Date(str);
    if (!isValidDate(maybeValidDate)) {
        return undefined;
    }

    return maybeValidDate;
}
