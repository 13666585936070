RemoveBeneficiaryController.$inject = ['$scope', '$modalInstance'];

export default function RemoveBeneficiaryController($scope, $modalInstance) {
    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    // this will remove the beneficiary
    $scope.remove = function () {
        $modalInstance.close($scope.comments);
    };
}
