import { Recordset } from '@treasury/FDL';
import { AlarmClock } from '@treasury/alarm-clock';
import AchExceptionServices from '@treasury/domain/channel/services/positive-pay/ach-exception-services.js';
import {
    ACH_EXCEPTION_CUTOFF,
    ACH_EXCEPTION_START,
} from '@treasury/domain/channel/types/arp/constants.js';
import { ListeningElementMixin } from '@treasury/omega/components';
import { openDialog } from '@treasury/omega/components/dialog';
import { date, money, string } from '@treasury/policy/primitives';
import { LitElement, html, nothing } from 'lit';
import { exceptionWidgetStyles } from './styles.js';

class AchExceptionWidget extends ListeningElementMixin(LitElement) {
    static get properties() {
        return {
            records: Object,
            recordset: Object,
            reviewRecordset: Object,
            isReviewStep: Boolean,
            alert: Object,
            saving: Boolean,
        };
    }

    constructor() {
        super();
        this.fields = {
            decisionChoice: string.thatIs.readOnly(),
            decisionTaken: string.thatIs.readOnly(),
            accountNumber: string.thatIs.readOnly(),
            achCompanyName: string.thatIs.readOnly(),
            amount: money.thatIs.readOnly(),
            postedDate: date.thatIs.readOnly(),
            exceptionType: string.thatIs.readOnly(),
            secCode: string.thatIs.readOnly(),
            entryDescription: string.thatIs.readOnly(),
        };
        this.columns = [
            {
                field: 'decisionChoice',
                label: 'Pay',
                type: 'radio-icon',
                value: 'Pay',
            },
            {
                field: 'decisionChoice',
                label: 'Return',
                type: 'radio-icon',
                value: 'Return',
            },
            {
                field: 'accountNumber',
                label: 'Account',
                type: 'command',
                action: 'viewAccount',
            },
            {
                field: 'achCompanyName',
                label: 'ACH Company',
            },
            {
                field: 'amount',
                label: 'Amount',
            },
            {
                field: 'postedDate',
                label: 'Posted Date',
            },
            {
                field: 'exceptionType',
                label: 'Type',
            },
            {
                field: 'secCode',
                label: 'SEC Code',
            },
            {
                field: 'entryDescription',
                label: 'Description',
            },
        ];
        this.reviewColumns = [
            {
                field: 'decisionChoice',
                label: 'Decision Taken',
            },
            {
                field: 'accountNumber',
                label: 'Account',
            },
            {
                field: 'achCompanyName',
                label: 'ACH Company',
            },
            {
                field: 'amount',
                label: 'Amount',
            },
            {
                field: 'postedDate',
                label: 'Posted Date',
            },
            {
                field: 'exceptionType',
                label: 'Type',
            },
            {
                field: 'secCode',
                label: 'SEC Code',
            },
            {
                field: 'entryDescription',
                label: 'Description',
            },
        ];
        this.alert = {
            title: '',
            message: '',
            visible: false,
            code: '',
        };
        this.actions = {
            viewAccount: record => {
                const accountId = record.getField('accountId');
                const institution = window.location.pathname.split('/')[1];
                window.location.href = `/${institution}/accounts?accountId=${accountId}&type=Deposit`;
            },
        };
        this.reviewRecordset = new Recordset(this.fields, () => []);
        this.isReviewStep = false;
        this.saving = false;
        this.disableReviewButton = true;
    }

    get fiClock() {
        return AlarmClock.getInstance();
    }

    connectedCallback() {
        super.connectedCallback();
        this.listenTo(this.fiClock, ACH_EXCEPTION_START, () => this.requestUpdate());
        this.listenTo(this.fiClock, ACH_EXCEPTION_CUTOFF, () => this.requestUpdate());
    }

    updated(changedProperties) {
        if (changedProperties.has('records')) {
            this.recordset = new Recordset(this.fields, () => this.records);
            this.recordset.requestUpdate();
            this.listenTo(this.recordset, 'updated', detail => {
                const existingRecord = this.reviewRecordset.getRecordById(detail.detail.record.id);
                if (existingRecord) {
                    this.reviewRecordset.deleteRecord(existingRecord);
                }
                this.reviewRecordset.insertRecord(detail.detail.record);
                this.disableReviewButton = false;
                this.requestUpdate();
            });
        }
    }

    async save() {
        this.saving = true;
        this.alert = {
            ...this.alert,
            visible: true,
            message: 'Saving decisions...',
            type: 'time-sensitive',
        };
        try {
            await AchExceptionServices.saveAchExceptions(
                this.reviewRecordset.allRecords.map(record => record.values)
            );
        } catch (e) {
            console.error(e);
            this.alert = {
                ...this.alert,
                visible: true,
                message:
                    e instanceof Error
                        ? e.message
                        : 'An error occurred while saving. Please try again.',
                type: 'error',
            };
        }
        this.saving = false;
        this.alert = {
            ...this.alert,
            visible: true,
            message: 'Decisions saved successfully!',
            type: 'success',
        };
        this.dispatchEvent(
            new CustomEvent('saved', {
                bubbles: true,
                detail: true,
            })
        );
        this.dispatchEvent(
            new CustomEvent('showSuccessMessage', {
                bubbles: true,
                detail: true,
            })
        );
        this.reviewRecordset = new Recordset(this.fields, () => []);
        this.toggleReviewStep();
    }

    handleActions({ action, record, rowIndex }) {
        this.actions[action](record, rowIndex);
    }

    toggleReviewStep() {
        this.isReviewStep = !this.isReviewStep;
        this.dispatchEvent(
            new CustomEvent('setReviewStep', {
                bubbles: true,
                detail: this.isReviewStep,
            })
        );
    }

    renderConfirmDialog() {
        return html`
            <div style="padding: 0 10px;">
                You are about to decision ACH exception items that can only be decisioned one time.
                <br />
                Are you sure you would like to proceed?
            </div>
        `;
    }

    renderOutsideCutoffTime(message) {
        return html`
            <div class="outside-cutoff-time">
                <p class="message">${message}</p>
            </div>
        `;
    }

    renderAlert() {
        const { code, time, message, type, title, actions, posture, visible } = this.alert;
        const renderedCode = code ? html`${code}: ` : nothing;
        const renderedTime = time ? html`<br />Time: ${time}` : nothing;

        return html`
            <div class="alert-wrapper">
                <omega-alert
                    type=${type}
                    title=${title}
                    posture=${posture}
                    ?isVisible=${visible}
                    @close=${() => {
                        this.alert = { ...this.alert, visible: false };
                    }}
                >
                    ${renderedCode} ${message} ${renderedTime} ${actions}
                </omega-alert>
            </div>
        `;
    }

    renderReadOnlyTable() {
        if (!this.recordset) return nothing;
        return html` <omega-table
            .columnDefinitions=${this.reviewColumns}
            .recordset=${this.recordset}
            @action=${e => this.handleActions(e.detail)}
        ></omega-table>`;
    }

    renderReviewTable() {
        return html`
            <omega-table
                .columnDefinitions=${this.reviewColumns}
                .recordset=${this.reviewRecordset}
                @action=${e => this.handleActions(e.detail)}
            ></omega-table>
            <omega-button-bar alignment="left" position="relative">
                <omega-button
                    type="primary"
                    @click=${async () => {
                        const result = await openDialog({
                            title: 'Confirm Decisions',
                            content: this.renderConfirmDialog(),
                            actions: resolve => html`
                                <omega-button
                                    type="approve"
                                    .loading=${this.saving}
                                    @click=${() => resolve('confirm')}
                                >
                                    Confirm
                                </omega-button>
                                <omega-button
                                    type="reject"
                                    .loading=${this.saving}
                                    @click=${() => resolve('')}
                                >
                                    Cancel
                                </omega-button>
                            `,
                        });
                        if (result === 'confirm') this.save();
                    }}
                    ?disabled=${this.saving}
                >
                    Save
                </omega-button>
                <omega-button
                    type="secondary"
                    @click=${this.toggleReviewStep}
                    ?disabled=${this.saving}
                >
                    Back
                </omega-button>
            </omega-button-bar>
        `;
    }

    renderTable() {
        return html`
            <omega-table
                .columnDefinitions=${this.columns}
                .recordset=${this.recordset}
                @action=${e => this.handleActions(e.detail)}
            ></omega-table>
            <omega-button-bar alignment="left" position="relative">
                <omega-button
                    type="primary"
                    @click=${this.toggleReviewStep}
                    .disabled=${this.disableReviewButton}
                >
                    Review
                </omega-button>
            </omega-button-bar>
        `;
    }

    render() {
        if (this.fiClock.isBefore(ACH_EXCEPTION_START)) {
            return this.renderOutsideCutoffTime(
                `ACH exception items are not available at this time. Please try again after ${this.fiClock.getAlarm(
                    ACH_EXCEPTION_START
                )} (${this.fiClock.timeZone}).`
            );
        }
        if (this.fiClock.isAfter(ACH_EXCEPTION_CUTOFF)) {
            return html`${this.renderOutsideCutoffTime(
                `ACH exception items are disabled because the current time is past the ${this.fiClock.getAlarm(
                    ACH_EXCEPTION_CUTOFF
                )} Cut-Off time (${this.fiClock.timeZone})`
            )}${this.renderReadOnlyTable()}`;
        }

        if (!this.recordset) return nothing;
        return html`
            ${this.alert.visible ? this.renderAlert() : nothing}
            ${this.isReviewStep ? this.renderReviewTable() : this.renderTable()}
        `;
    }

    // eslint-disable-next-line @treasury/style-includes-host-display
    static get styles() {
        return [exceptionWidgetStyles];
    }
}
window.customElements.define('ach-exception-widget', AchExceptionWidget);
export default AchExceptionWidget;
