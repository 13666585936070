import { exists, isNonEmptyString } from '@treasury/utils';
import {
    ErrorResponseModelDto as BoErrorDto,
    ErrorHttpContentDto as BoHttpContentErrorDto,
    RequestResponseDto as BoRequestResponseDto,
    ResponseDetailModelDto as BoResponseDetailDto,
} from '../bo';
import {
    ErrorResponseModelDto as ChannelErrorDto,
    ErrorSummaryDto as ChannelErrorSummaryDto,
    ErrorHttpContentDto as ChannelHttpContentDto,
    RequestResponseDto as ChannelRequestResponseDto,
    ResponseDetailModelDto as ChannelResponseDetailDto,
} from '../channel';

type ErrorDto = ChannelErrorDto & BoErrorDto;
type HttpContentErrorDto = ChannelHttpContentDto & BoHttpContentErrorDto;
type ResponseDetailDto = ChannelResponseDetailDto & BoResponseDetailDto;
export type ErrorSummaryDto = ChannelErrorSummaryDto;

/**
 * Contract for responses which communicate their error state on a valid `HTTP 200` status.
 */
export type ErrorResponseDto = ChannelRequestResponseDto & BoRequestResponseDto;

/**
 * TM API error DTO shape encompassing all permutations.
 */
export interface ApiErrorDto
    extends Partial<ErrorDto>,
        Partial<ResponseDetailDto>,
        Partial<HttpContentErrorDto> {
    /**
     * Sometimes specified as an alternative to `message`.
     */
    error?: string;
    /**
     * Sometimes specified as an alternative to `responseCode`.
     */
    errorCode?: string;
    /**
     * Sometimes specified as an alternative to `message`.
     */
    errorMessage?: string;
    errorSummary?: ErrorSummaryDto;
    /**
     * Sometimes specified as an alternative to `time`.
     */
    timestamp?: string;
}

const errorProps: Readonly<(keyof ApiErrorDto)[]> = Object.freeze([
    'error',
    'errorCode',
    'errorMessage',
    'errorSummary',
]);

export function isApiErrorDto(obj: unknown): obj is ApiErrorDto {
    const maybeErrorDto = obj as ApiErrorDto;
    const { message, code, time, responseDetails } = maybeErrorDto;
    if (responseDetails && Array.isArray(responseDetails) && typeof time === 'string') {
        return true;
    }

    if (typeof message === 'string' && typeof code === 'number' && typeof time === 'string') {
        return true;
    }

    return errorProps.some(
        propName => propName in maybeErrorDto && isNonEmptyString(maybeErrorDto[propName])
    );
}

export function isErrorResponse(obj: object): obj is ErrorResponseDto {
    if (!exists(obj)) {
        return false;
    }

    const maybeErrResponse = obj as ErrorResponseDto;
    if (exists(maybeErrResponse.success)) {
        if (maybeErrResponse.success === true) {
            return false;
        }

        if (maybeErrResponse.success === false) {
            return exists(maybeErrResponse.errorMessage);
        }

        return false;
    }

    return false;
}
