/* eslint-disable lit-a11y/anchor-is-valid */
import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { ObservationSource } from '@jack-henry/frontend-utils/observable';
import { Record as FdlRecord } from '@treasury/FDL/record';
import { Recordset, RecordsetEvent, RecordsetFetchArgs } from '@treasury/FDL/recordset';
import { RecordsetSummaryModel } from '@treasury/FDL/recordset/recordset-summary-model';
import { CancelWireModelDto, LookupListModelDto, WireCompanyModelDto } from '@treasury/api/channel';
import { NavigationService } from '@treasury/core/navigation';
import { EntitlementsService } from '@treasury/domain/channel/services';
import { UsersService } from '@treasury/domain/channel/services/ach';
import { DownloadEvent } from '@treasury/domain/channel/services/download';
import { CurrenciesService } from '@treasury/domain/services/currencies.service';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { Wire, WireSearchCriteria, WiresService } from '@treasury/domain/wires';
import { ListeningElementMixin } from '@treasury/omega/components/listening-element';
import '@treasury/omega/components/omega-filter-bar.js';
import { OmegaColumnDefinition } from '@treasury/omega/components/table';
import '@treasury/omega/layouts/omega-report';
import { AlertMixin } from '@treasury/omega/mixins';
import {
    DialogButtonPosture,
    OmegaDialogExitReason,
    OmegaDialogService,
} from '@treasury/omega/services';
import { OmegaReportAction, OmegaReportFilter, SummaryTileVm } from '@treasury/omega/types';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { DownloadPageId } from '../../../app/constants';
import '../../components/blocking-loader';
import Entitlements from '../../utilities/entitlements';
import { columns, createFields, createFilters } from './definitions';
import { WirePaymentActivityFilterParameters, mapFilterParams } from './models/filter-params.model';

type WiresDownloadEvent = DownloadEvent<WirePaymentActivityFilterParameters>;

export const TagName = 'wire-payment-activity';
const Mixins = AlertMixin(ListeningElementMixin(LitElement));
@customElement(TagName)
export class WirePaymentActivity extends Mixins {
    private isFeatureWireSftpEnabled = false;

    @InjectProperty()
    private declare service: WiresService;

    @InjectProperty()
    private declare usersService: UsersService;

    @InjectProperty()
    private declare currenciesService: CurrenciesService;

    @InjectProperty({
        token: FeatureFlagService,
        async: true,
    })
    private declare ffServicePromise: Promise<FeatureFlagService>;

    @InjectProperty()
    private declare navService: NavigationService;

    public readonly containerTitle = 'Wire Payment Activity';

    @state()
    private actions = {};

    @state()
    private columns: OmegaColumnDefinition<Wire>[] = [];

    @state()
    private filters: OmegaReportFilter<WirePaymentActivityFilterParameters>[] = [];

    @state()
    private originalData: Wire[] = [];

    @state()
    private selectedSummaryTile?: SummaryTileVm;

    @state()
    private isLoading = true;

    @state()
    private isDownloading = false;

    @state()
    private isSaving = false;

    @state()
    private hasApproveInternational = false;

    @state()
    private recordSet!: Recordset<Wire, WirePaymentActivityFilterParameters>;

    @state()
    private reportActions?: OmegaReportAction<any>[];

    @state()
    private summary?: RecordsetSummaryModel[];

    @state()
    private wireFileName?: string;

    @state()
    private wireFileId?: number;

    @InjectProperty()
    private declare dialogService: OmegaDialogService;

    private entitlementsService = new EntitlementsService();

    private reportInformation = html`<div style="max-width: 350px;">
        <p>
            <b>Wire Payment Activity</b> - Includes all payments including recurring payments
            regardless of payment status (Payments Pending Approval, Scheduled, Submitted, etc.).
            Utilize the search feature to search payment history.
        </p>
        <p>
            <b>Recurring Wires</b> - Includes only recurring payments and the details (Frequency,
            Create Date, Next Payment Date, End Date, etc.) associated with the respective payment
            series.
        </p>
        <p>
            <b>Wire File Activity</b> - Views all wire files that have been submitted to the bank
            for processing.
        </p>
    </div>`;

    private reportLinks = [
        {
            title: 'Recurring Wires',
            route: 'payables.wire.recurring-wire-list',
        },
        {
            title: 'Wire File Activity',
            route: 'payables.wire.wire-file-activity',
        },
    ];

    private setupSearchForWireFile(searchParams: WireSearchCriteria, wireFileId: number) {
        const clearedParams = { ...searchParams };
        clearedParams.wireFileId = wireFileId;
        clearedParams.createdDate = undefined;
        clearedParams.createdDateStart = undefined;
        clearedParams.createdDateEnd = undefined;
        clearedParams.valueDate = undefined;
        clearedParams.valueDateStart = undefined;
        clearedParams.valueDateEnd = undefined;
        return clearedParams;
    }

    private async fetchData(params: RecordsetFetchArgs<Wire, WirePaymentActivityFilterParameters>) {
        const { parameters } = params;
        let searchParams = mapFilterParams(parameters);
        // If a wireFileId is present then we navigated here from the Wire File Activity page.
        // In this case we want to search by wireFileId and clear the default date range.
        if (this.wireFileId) {
            searchParams = this.setupSearchForWireFile(searchParams, this.wireFileId);
        }
        const result = await this.service.searchWithTotals(searchParams, 'One Time');
        // Only use the summary tiles if the data set has summary records.
        // This allows the Feature Flag on the Services side to control this behavior.
        this.summary = result.summary?.length ? result.summary : undefined;
        this.originalData = result.data;
        return result.data;
    }

    private goToWireDetail(wire: Wire) {
        this.navService.navigate('payables.wire.wire-details', {
            id: wire.wireId,
            type: 'wireList',
            fileName: this.wireFileName,
            wireFileId: this.wireFileId,
        });
    }

    private goToWireFileDetail(wireFileName: string) {
        this.navService.navigate('payables.wire.wire-file-activity', {
            wireFileName,
        });
    }

    private createObservedPrompt(promptText: string) {
        const input$ = new ObservationSource<string>(({ emit }) => {
            emit('');
        });
        const prompt = html`<style>
                .prompt {
                    width: 100%;
                    height: 100px;
                }
            </style>
            <p>${promptText}</p>
            <p>Comments:</p>
            <textarea
                value=""
                class="prompt"
                @input=${(e: Event) => input$.emit((e.target as HTMLTextAreaElement)?.value)}
            ></textarea> `;
        return { prompt, input$ };
    }

    private async showCancelWireDialog(record: FdlRecord<Wire>) {
        const { prompt, input$ } = this.createObservedPrompt(
            'Are you sure you want to cancel this wire payment?'
        );
        const response = await this.dialogService.open(prompt, 'Cancel Wire', {
            buttons: {
                [OmegaDialogExitReason.Cancel]: {
                    label: 'Close',
                },
                [OmegaDialogExitReason.Confirm]: {
                    label: 'Cancel Wire',
                    posture: DialogButtonPosture.Destructive,
                },
            },
        }).closed;

        return this.handleCancelDialogClose(response.reason, record, await input$.toPromise(true));
    }

    private async handleCancelDialogClose(
        reason: OmegaDialogExitReason,
        record: FdlRecord<Wire>,
        comments: string
    ) {
        const wireId = record.getField('wireId') ?? 0;
        if (wireId) {
            const cancelWireModel: CancelWireModelDto = {
                id: 0,
                updatedDate: '',
                wireId,
                comments,
                securityMessage: {
                    actionType: 'Cancel WIRE Payment',
                    hasAlternate: false,
                    challengeMethodTypeId: 0,
                },
            };
            if (reason === OmegaDialogExitReason.Confirm) {
                try {
                    this.isDownloading = true;
                    await this.service.deleteWirePayment(cancelWireModel);
                    this.isSaving = true;
                    this.recordSet.requestHardUpdate();
                } catch (e) {
                    console.log(e);
                } finally {
                    this.isDownloading = false;
                }
                return true;
            }
        }

        return false;
    }

    private async showWireActionDialog(type: string) {
        const checkedWires = this.recordSet.recordsMatching('isChecked', true);
        const typeText = checkedWires.length > 1 ? 'these wires' : 'this wire';
        const titlePlurality = checkedWires.length > 1 ? 's' : '';
        const typeLabel = type === 'approve' ? 'Approve' : 'Reject';
        const { prompt, input$ } = this.createObservedPrompt(
            `Are you sure you want to ${type} ${typeText}?`
        );

        const response = await this.dialogService.open(
            prompt,
            `${typeLabel} Wire${titlePlurality}`,
            {
                buttons: {
                    [OmegaDialogExitReason.Cancel]: {
                        label: 'Close',
                    },
                    [OmegaDialogExitReason.Confirm]: {
                        label: typeLabel,
                        posture:
                            type === 'approve'
                                ? DialogButtonPosture.Constructive
                                : DialogButtonPosture.Destructive,
                    },
                },
            }
        ).closed;

        return this.handleActionDialogClose(response.reason, type, await input$.toPromise(true));
    }

    private async handleActionDialogClose(
        reason: OmegaDialogExitReason,
        type: string,
        comments: string
    ) {
        const checkedWires = this.recordSet.recordsMatching('isChecked', true);
        if (checkedWires) {
            const batch: LookupListModelDto = {
                id: 0,
                updatedDate: '',
                lookups: checkedWires.map(wire => ({
                    id: 0,
                    updatedDate: '',
                    key: `${wire.getField('wireId')}`,
                    value: comments,
                })),
            };
            if (reason === OmegaDialogExitReason.Confirm) {
                try {
                    this.isDownloading = true;
                    await this.service.batchApproveOrReject(batch, type);
                    this.isSaving = true;
                    this.recordSet.requestHardUpdate();
                } catch (e) {
                    console.log(e);
                } finally {
                    this.isDownloading = false;
                }
                return true;
            }
        }

        return false;
    }

    private createFields() {
        return createFields(this.usersService, this.hasApproveInternational);
    }

    async firstUpdated() {
        this.isFeatureWireSftpEnabled = await (
            await this.ffServicePromise
        ).isEnabled(Feature.WireSftpEnabled);

        this.hasApproveInternational = await this.entitlementsService.hasEntitlement(
            Entitlements.Wire.ApproveFxWirePayment.permission
        );

        this.recordSet = new Recordset<Wire, WirePaymentActivityFilterParameters>(
            this.createFields(),
            args => this.fetchData(args)
        );

        const { params } = await this.navService.getRouteData<{
            fileName?: string;
            wireFileId?: number;
        }>();

        this.wireFileName = params.fileName;
        this.wireFileId = params.wireFileId;

        this.actions = {
            review: (record: FdlRecord<Wire>) => this.goToWireDetail(record.values),
            cancelWirePayment: (record: FdlRecord<Wire>) => this.showCancelWireDialog(record),
            openWireFileDetails: (record: FdlRecord<Wire>) =>
                this.goToWireFileDetail(record.values.fileName ?? ''),
        };
        this.reportActions = [
            {
                type: 'approve',
                label: 'Approve',
                action: async () => {
                    this.showWireActionDialog('approve');
                },
                isDisabled: () => this.recordSet.noRecordsMatch('isChecked', true),
                visibleWhen: () => true,
            },
            {
                type: 'reject',
                label: 'Reject',
                action: async () => {
                    this.showWireActionDialog('reject');
                },
                isDisabled: () => this.recordSet.noRecordsMatch('isChecked', true),
                visibleWhen: () => true,
            },
        ];

        const wireCompanies = (await this.service.getWireCompanies(false)).map(wc =>
            wc.toDto()
        ) as WireCompanyModelDto[];
        const debitAccounts = await this.service.fetchTransferAccounts();
        const availableCurrencies = await this.currenciesService.getAll();

        this.columns = columns.filter(
            column => !(column.field === 'source' && !this.isFeatureWireSftpEnabled)
        );
        this.filters = createFilters(wireCompanies, debitAccounts, availableCurrencies);
        this.listenTo(this.recordSet, RecordsetEvent.Loading, (event: CustomEvent) => {
            if (!event.detail.loading) {
                if (this.isSaving) {
                    this.filterSummaryTiles();
                } else {
                    this.selectedSummaryTile = undefined;
                }
                this.isSaving = false;
            }
        });

        this.isLoading = false;
    }

    private async handleDownload({ detail }: WiresDownloadEvent) {
        const { type, filter } = detail;
        const searchParams = mapFilterParams(filter);
        this.isDownloading = true;
        try {
            await this.service.downloadWires(type, DownloadPageId.WireList, searchParams);
        } catch (error) {
            console.error(error);
        } finally {
            this.isDownloading = false;
        }
    }

    private handleSummaryTileClick(event: CustomEvent<SummaryTileVm>) {
        this.selectedSummaryTile = event.detail;
        this.filterSummaryTiles();
    }

    private filterSummaryTiles() {
        if (this.selectedSummaryTile) {
            const filteredData = this.originalData.filter(record => {
                if (this.selectedSummaryTile?.title === 'Cancelled/Deleted') {
                    return record.status === 'Cancelled' || record.status === 'Deleted';
                }
                return record.status === this.selectedSummaryTile?.title;
            });
            if (filteredData.length === 0) {
                this.selectedSummaryTile = undefined;
                this.recordSet.setData(this.originalData);
            } else {
                this.recordSet.setData(filteredData);
            }
        } else {
            this.recordSet.setData(this.originalData);
        }
    }

    private renderFilenameLink() {
        if (this.wireFileName) {
            return html` <h4>
                <a
                    href="javascript:void(0)"
                    @click=${() => this.goToWireFileDetail(this.wireFileName ?? '')}
                    >${this.wireFileName}</a
                >
            </h4>`;
        }
        return nothing;
    }

    private renderTypeToFilter() {
        return html`<div style="margin-left:15px">
            <omega-filter-bar
                id="type-to-filter"
                .filters=${[]}
                .recordset=${this.recordSet}
            ></omega-filter-bar>
        </div>`;
    }

    public renderBlockingLoader() {
        if (this.isLoading || this.isDownloading) return html`<blocking-loader></blocking-loader>`;
        return nothing;
    }

    public render() {
        if (this.isLoading) return this.renderBlockingLoader();
        return html`${this.renderAlert()}${this.renderBlockingLoader()}
            <div class="report-container">
                <omega-report
                    flyout
                    autostart
                    .title=${this.containerTitle}
                    .filters=${this.filters}
                    .recordset=${this.recordSet}
                    .summary=${this.summary}
                    .selectedSummaryTile=${this.selectedSummaryTile}
                    .columns=${this.columns}
                    .actions=${this.actions}
                    .reportActions=${this.reportActions}
                    callToAction="Create New Payment"
                    @callToActionClicked=${() =>
                        this.navService.navigate('payables.wire.create-wire')}
                    @summaryTileClicked=${this.handleSummaryTileClick}
                    .reportInformation=${this.reportInformation}
                    .reportLinks=${this.reportLinks}
                    .options=${['download']}
                    .downloadOptions=${[
                        'Summary - PDF',
                        'Detail - PDF',
                        'Summary - CSV',
                        'Detail - CSV',
                    ]}
                    @reportDownload=${this.handleDownload}
                >
                    <div slot="above-table">
                        ${this.renderFilenameLink()}${this.renderTypeToFilter()}
                    </div>
                </omega-report>
            </div>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }

            h4 {
                margin: 10px;
                font-size: 17px;
                font-weight: normal;
            }

            h4 a {
                text-decoration: none;
            }
        `;
    }
}
