WireTemplateDetailsController.$inject = [
    '$scope',
    '$stateParams',
    '$timeout',
    'toaster',
    'wireService',
    '$state',
    '$modal',
    'modalService',
    'utilityService',
    'wireCompaniesService',
    'companyAccountsService',
    'holidaysService',
    'entitlementsService',
    'currenciesService',
    '$location',
    'securityService',
    'activityService',
    'resourceType',
    'auditCode',
    'beneficiariesService',
    'downloadPageId',
    'regexConstants',
];

export default function WireTemplateDetailsController(
    $scope,
    $stateParams,
    $timeout,
    toaster,
    wireService,
    $state,
    $modal,
    modalService,
    utilityService,
    wireCompaniesService,
    companyAccountsService,
    holidaysService,
    entitlementsService,
    currenciesService,
    $location,
    securityService,
    activityService,
    resourceType,
    auditCode,
    beneficiariesService,
    downloadPageId,
    regexConstants
) {
    const createWireInternationalEntitlement = 'Wire, International, Create Wire';
    const createWireDomesticEntitlement = 'Wire, Domestic, Create Wire';
    let hasCreateWireEntitlement = false;

    $scope.downloadPageId = downloadPageId.WireTemplateDetail;
    $scope.id = $stateParams.id;
    $scope.type = $stateParams.type;
    $scope.approveOrReject = approveOrReject;
    $scope.isApprovedOrRejected = isApprovedOrRejected;
    $scope.findAccount = findAccount;
    $scope.setForm = setForm;
    $scope.confirm = confirm;
    $scope.isObject = isObject;
    $scope.close = close;
    $scope.edit = edit;
    $scope.review = review;
    $scope.print = print;
    $scope.goToPreviousScreen = goToPreviousScreen;
    $scope.deleteNote = deleteNote;
    $scope.addNote = addNote;
    $scope.findBeneficiaries = findBeneficiaries;
    $scope.getBeneficiaries = getBeneficiaries;
    $scope.getPlaceHolderText = getPlaceHolderText;
    $scope.hasAccessPermissions = hasAccessPermissions;
    $scope.checkStatus = checkStatus;
    $scope.cancel = cancel;
    $scope.selectWireCompany = selectWireCompany;
    $scope.canEdit = canEdit;
    $scope.canApproveOrReject = canApproveOrReject;
    $scope.validateTemplateName = validateTemplateName;
    $scope.batchInitiate = batchInitiate;
    $scope.canInitiate = canInitiate;
    $scope.hideInitiate = hideInitiate;
    $scope.canCreateInternationalUsd = false;
    $scope.pristine = null;
    $scope.isConfirmation = false;
    $scope.isEditing = false;
    $scope.isReviewing = false;
    $scope.wireTemplateNameRegex = regexConstants.WireAlphaNumericPlusSpecialCharsRegex;
    $scope.isTemplateNameValid = isTemplateNameValid;
    $scope.beneficiaryReferenceRegex = regexConstants.BeneficiaryReferenceRegex;
    $scope.wireAdditionalInformationRegex = regexConstants.WireAlphaNumericPlusSpecialCharsRegex;

    function isTemplateNameValid() {
        return (
            !!$scope.wireTemplate &&
            !!$scope.wireTemplate.name &&
            !!$scope.form &&
            !!$scope.form.wireTemplateName &&
            $scope.form.wireTemplateName.$valid
        );
    }

    init();

    function batchInitiate() {
        const templates = [];
        templates.push($scope.wireTemplate);
        wireService.setSelectedTemplates(templates);
        $state.go('payables.wire.create-wire');
    }

    function validateTemplateName() {
        $scope.form.wireTemplateName.$setValidity('unique', true);
        if ($scope.wireTemplate.name !== $scope.pristine.name) {
            wireService.validateTemplateName($scope.wireTemplate.name).then(resp => {
                if (resp.isValid) {
                    $scope.form.wireTemplateName.$setValidity('unique', true);
                } else {
                    $scope.form.wireTemplateName.$setValidity('unique', false);
                }
            });
        }
    }

    function findBeneficiaries(type) {
        const modalInstance = $modal.open({
            template: require('../views/beneficiaryModalView.html'),
            size: 'lg',
            controller: 'BeneficiarySelectionController',
            backdrop: 'static',
            resolve: {
                type() {
                    return type;
                },
                beneficiaries() {
                    return $scope.beneficiaries;
                },
                enableFreeFormEntry() {
                    return false;
                },
            },
        });
        modalInstance.result.then(selectedBeneficiary => {
            $scope.wireTemplate.beneficiary = angular.copy(selectedBeneficiary);
            $scope.form.$dirty = true;
        });
    }

    function canApproveOrReject() {
        return (
            !!$scope.wireTemplate &&
            $scope.wireTemplate.status === 'Pending Approval' &&
            !$scope.isEditing &&
            !$scope.isConfirmation
        );
    }

    function init() {
        // Reload required so that any changes to entitlements take effect immediately without re-authenticating
        entitlementsService.reload();

        wireCompaniesService.getWireConfiguration().then(response => {
            $scope.wireConfig = response;
            $scope.canCreateInternationalUsd =
                response.fiWireProductEnabled &&
                response.fiInternationalUsdEnabled &&
                response.companyWireProductEnabled &&
                response.companyInternationalUsdEnabled;
        });

        wireService.getWireTemplate($scope.id).then(response => {
            $scope.wireTemplate = angular.copy(response);
            $scope.accountDisplayField = $scope.wireTemplate.debitAccount.showAccountNickName
                ? 'name'
                : 'number';
            $scope.pristine = angular.copy(response);
            $scope.useThreeLineAddress = canUseThreeLineAddress();

            if ($scope.type === 'Edit') {
                $scope.isEditing = true;
                getAccounts($scope.wireTemplate.wireCompany.id);
                getWireCompanies();
                getBeneficiaries();
                wireCompaniesService.getWireConfiguration().then(response => {
                    $scope.requirePurpose = response.requirePurpose;
                });
            }
        });
    }

    function canEdit() {
        return (
            !!$scope.wireTemplate &&
            !$scope.isReviewing &&
            !$scope.isEditing &&
            checkStatus($scope.wireTemplate.status) &&
            hasAccessPermissions('Edit') &&
            !$scope.isConfirmation
        );
    }

    function canInitiate() {
        if ($scope.wireTemplate == null) {
            return false;
        }

        hasCreateWireEntitlement = $scope.wireTemplate.isInternational
            ? entitlementsService.hasEntitlement(createWireInternationalEntitlement)
            : entitlementsService.hasEntitlement(createWireDomesticEntitlement);

        const passInternationalUsdCheck = $scope.wireTemplate.isInternational
            ? $scope.canCreateInternationalUsd
            : true;

        return (
            !$scope.isReviewing &&
            !$scope.isEditing &&
            !$scope.isConfirmation &&
            hasCreateWireEntitlement &&
            passInternationalUsdCheck
        );
    }

    function hideInitiate() {
        return (
            !$scope.wireTemplate ||
            $scope.wireTemplate.isPendingEdit ||
            $scope.wireTemplate.status === 'Pending Approval' ||
            $scope.wireTemplate.status === 'Approval Rejected'
        );
    }

    function print() {
        window.print();
    }

    function goToPreviousScreen() {
        clearWireErrors();
        $scope.isReviewing = false;
        $scope.isEditing = true;
        $scope.form.$valid = true;
        $scope.form.$dirty = true;
    }

    function setForm(form) {
        if (!$scope.form) {
            $scope.form = form;
        }
    }

    function review() {
        $scope.isReviewing = true;
        $scope.isEditing = false;
    }

    function checkStatus(status) {
        switch (status) {
            case 'Pending Approval':
                return false;
            case 'Rejected':
                return true;
            case 'Approval Rejected':
                return true;
            case 'Ready':
                return true;
            case 'Deleted':
                return false;
            case 'Pending Edits':
                return true;
        }
    }

    function findAccount(id) {
        getAccounts(id, () => {
            const modalInstance = $modal.open({
                template: require('../views/accountModalView.html'),
                size: 'md',
                controller: 'AccountsController',
                backdrop: 'static',
                resolve: {
                    wireId() {
                        return id;
                    },
                    companyAccounts() {
                        return $scope.companyAccounts;
                    },
                },
            });
            modalInstance.result.then(selectedAccount => {
                $scope.wireTemplate.debitAccount = angular.copy(selectedAccount);
                $scope.form.$dirty = true;
            });
        });
    }

    function getPlaceHolderText(index) {
        return `Sender to Receiver Info. Line ${index + 1}`;
    }

    function isObject(value) {
        let result = false;
        if (value === null || value === undefined) {
            result = false;
        } else if (typeof value === 'object') {
            result = true;
        }
        return result;
    }

    function addNote() {
        if ($scope.wireTemplate.notes.length === 4) {
            return;
        }
        $scope.wireTemplate.notes.push(null);
    }

    function deleteNote(index) {
        $scope.wireTemplate.notes.splice(index, 1);
    }

    function selectWireCompany(obj) {
        getAccounts(obj.id);
    }

    function getAccounts(id, callback) {
        let edit;
        if ($scope.wireTemplate.isInternational === true) {
            edit = 'editIntl';
        } else {
            edit = 'editDomestic';
        }
        wireCompaniesService.getDebitAccounts(id, edit).then(response => {
            if (response.length > 0 && response[0] !== null && typeof response[0] !== 'undefined') {
                $scope.accountDisplayField = response[0].showAccountNickName ? 'name' : 'number';
            }
            $scope.companyAccounts = response;
            if ($scope.wireTemplate.debitAccount) {
                const account = $scope.companyAccounts.reduce(
                    (acc, val) => (val.id === $scope.wireTemplate.debitAccount.id ? val : acc),
                    null
                );
                if (account) {
                    $scope.wireTemplate.debitAccount = account;
                } else {
                    $scope.wireTemplate.debitAccount = null;
                }
            }
            if (typeof callback === 'function') {
                callback();
            }
        });
    }

    function getBeneficiaries() {
        const filter = {
            status: 'Ready',
            beneficiaryType: $scope.wireTemplate.isInternational ? 'International' : 'Domestic',
            requireEntitlement: false,
        };

        beneficiariesService.getFilteredBeneficiariesList(filter).then(response => {
            $scope.beneficiaries = angular.copy(response);
            if ($scope.wireTemplate.beneficiary) {
                const beneficiary = $scope.beneficiaries.reduce(
                    (bene, val) => (val.id === $scope.wireTemplate.beneficiary.id ? val : bene),
                    null
                );
                if (beneficiary) {
                    $scope.wireTemplate.beneficiary = beneficiary;
                } else {
                    $scope.wireTemplate.beneficiary = null;
                }
            }
        });
    }

    function confirm() {
        if ($scope.wireTemplate) {
            securityService
                .verifyUser('Edit WIRE Template', $scope.wireTemplate, () =>
                    wireService.updateWireTemplate($scope.wireTemplate)
                )
                .then(response => {
                    const hasError = parseIndividualErrors(response);
                    if (hasError === false) {
                        $scope.wireTemplate = angular.copy(response);
                        $scope.pristine = angular.copy(response);
                        $scope.isReviewing = false;
                        $scope.isEditing = false;
                        $scope.isConfirmation = true;
                    }
                });
        }
    }

    function getWireCompanies() {
        wireCompaniesService.getAll(true).then(response => {
            $scope.wireTemplateCompanies = response;

            if ($scope.wireTemplate.wireCompany) {
                const company = $scope.wireTemplateCompanies.reduce(
                    (acc, val) => (val.id === $scope.wireTemplate.wireCompany.id ? val : acc),
                    null
                );
                if (company) {
                    $scope.wireTemplate.wireCompany = company;
                }
                getAccounts($scope.wireTemplate.wireCompany.id);
            }
        });
    }

    function edit() {
        $scope.isEditing = true;
        getWireCompanies();
        getBeneficiaries();
        getAccounts($scope.wireTemplate.wireCompany.id);
        wireCompaniesService.getWireConfiguration().then(response => {
            $scope.requirePurpose = response.requirePurpose;
        });
    }

    function close() {
        $state.go('payables.wire.wire-template-list');
    }

    function approveOrReject(type) {
        const modalInstance = $modal.open({
            template: require('../views/approveOrRejectWire.html'),
            size: 'md',
            controller: 'ApproveOrRejectWireController',
            backdrop: 'static',
            resolve: {
                type() {
                    return type;
                },
                listType() {
                    return $scope._type;
                },
                isDli() {
                    return false;
                },
            },
        });
        modalInstance.result.then(comments => {
            $scope.wireTemplate.comments = comments;
            let actionType;
            if (type === 'Approve') {
                actionType = 'Approve WIRE Template';
            } else {
                actionType = 'Reject WIRE Template';
            }

            const message = { value: comments, key: 'test' };
            securityService
                .verifyUser(actionType, message, () =>
                    wireService.approveOrRejectWireTemplate(type, $scope.wireTemplate.id, message)
                )
                .then(response => {
                    const hasError = parseIndividualErrors(response);
                    if (!hasError) {
                        $state.go('payables.wire.wire-template-list');
                    }
                });
        });
    }

    function hasAccessPermissions(permissionType) {
        if ($scope.wireTemplate !== null && $scope.wireTemplate !== undefined) {
            let result = false;
            angular.forEach($scope.wireTemplate.permissions, permission => {
                if (permission.permission === permissionType) {
                    result = true;
                }
            });
            return result;
        }
    }

    function isApprovedOrRejected() {
        if ($scope.wireTemplate !== null && $scope.wireTemplate !== undefined) {
            if (
                $scope.wireTemplate.status === 'Approved' ||
                $scope.wireTemplate.status === 'Approval Rejected'
            ) {
                return true;
            }
            return false;
        }
    }

    function isRejected() {
        if (
            $scope.wireTemplate !== null &&
            $scope.wireTemplate !== undefined &&
            $scope.wireTemplate.status === 'Rejected'
        ) {
            return true;
        }
    }

    function parseIndividualErrors(transaction) {
        let hasErrors = false;
        if (!!transaction.errorSummary && !!transaction.errorSummary.summaryMessage) {
            $scope.errorDetails = [];
            hasErrors = true;
            $scope.errorMessage = transaction.errorSummary.summaryMessage;
            angular.forEach(transaction.errorSummary.details, item => {
                if (!!item.message && !!item.messageList && item.messageList.length > 0) {
                    angular.forEach(item.messageList, message => {
                        $scope.errorDetails.push(message.value);
                    });
                } else if (item.message) {
                    $scope.errorDetails.push(item.message);
                }
            });
        } else {
            clearWireErrors();
        }
        return hasErrors;
    }

    function clearWireErrors() {
        $scope.errorMessage = null;
        $scope.errorDetails = null;
    }

    function cancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Close',
                actionButtonText: 'Cancel Edits',
                headerText: 'Confirm Cancellation',
                bodyText: 'Are you sure you want to cancel the changes you have made?',
                submit(result) {
                    $state.go('payables.wire.wire-template-list');
                    $modalInstance.close();
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            close();
        }
    }

    function canUseThreeLineAddress() {
        return (
            $scope.wireTemplate &&
            beneficiariesService.canUseThreeLineAddress($scope.wireTemplate.beneficiary)
        );
    }
}
