/* eslint-disable import/no-extraneous-dependencies */
import { AchCompanyRequests } from '@treasury/domain/channel/requests/ach';
import {
    AchBankDto,
    AchCompany,
    AchFrequencyDefinition,
    PaymentHeader,
    PaymentRecipient,
    SecCode,
} from '@treasury/domain/channel/types/ach';
import { boolean, FieldType, Record, string } from '@treasury/FDL';
import { html } from 'lit';
import { Primitives as fieldTypes } from '../index';
import yesNoFormatter from '../lib/formatters/yesNo.formatter.js';
import alphanumeric from '../lib/validators/alphanumeric.validator';
import nachaAlphanumeric from '../lib/validators/nacha/nacha-alphanumeric.validator.js';
import noPunctuation from '../lib/validators/noPunctuation.validator.js';

interface AchFrequencyViewModel extends AchFrequencyDefinition {
    isChecked: boolean;
}

export const achCompanyName = string.with
    .cellClass('company-name')
    .and.minColumnWidth(300)
    .and.targetColumnWidth(320)
    .and.maxColumnWidth(400)
    .and.defaultValue('')
    .and.maxLength(16)
    .and.validator(nachaAlphanumeric)
    .with.description(
        'The string name of an ACH Company. Generally used for display in tabular data.'
    );

export const achAccountNumber = new FieldType<string>().with
    .validator(alphanumeric)
    .and.validator(noPunctuation)
    .and.minColumnWidth(100)
    .and.targetColumnWidth(150)
    .and.maxColumnWidth(175)
    .and.maxLength(17)
    .with.description('The Account number used to receive an ACH payment.');

export const achTransactionType = (
    paymentHeaderRecord: Record<PaymentHeader>,
    onUsAchBanks: Array<AchBankDto>
) =>
    new FieldType<PaymentHeader['transactionType']>().with
        .options([
            { text: 'Credit', value: 'CR' },
            { text: 'Debit', value: 'DR' },
        ])
        .and.defaultValue(() => {
            const achCompany: any = paymentHeaderRecord.getField('achCompany');
            if (achCompany.notOnUsTransactionTypes === 'Debit Only') {
                return 'DR';
            }
            return 'CR';
        })
        .with.validator({
            name: 'Ach company allows selected payment transaction type',
            validate: (value, modelValue, record: Record<PaymentRecipient>) => {
                const achCompany = paymentHeaderRecord.getField('achCompany');
                if (achCompany) {
                    const creditTypes = ['Debits and Credits', 'Credit Only'];
                    const debitTypes = ['Debits and Credits', 'Debit Only'];
                    if (
                        onUsAchBanks.some(
                            (bank: AchBankDto) => bank.bankId === record.getField('bank')?.bankId
                        )
                    ) {
                        return true;
                    }
                    if (value === 'CR') {
                        return creditTypes.includes(achCompany.notOnUsTransactionTypes);
                    }
                    if (value === 'DR') {
                        return debitTypes.includes(achCompany.notOnUsTransactionTypes);
                    }
                }
                return false;
            },
        })
        .and.label('Credit/Debit')
        .and.required();

export const achAccountType = new FieldType<string>().with
    .options([
        { text: 'Checking', value: 'Checking' },
        { text: 'Savings', value: 'Savings' },
        { text: 'General Ledger', value: 'General Ledger' },
        { text: 'Loan', value: 'Loan' },
    ])
    .and.minColumnWidth(100)
    .and.targetColumnWidth(135)
    .and.maxColumnWidth(200);

export const achCompany = (service = AchCompanyRequests) =>
    new FieldType<AchCompany>().with
        .formatter(company => company.companyName)
        .and.options<AchCompany>({
            fetch: service?.getAchCompanies,
            text: company => company.companyName,
            value: company => company,
        })
        .with.filtering()
        .and.search({
            title: 'Search ACH Companies',
            columns: [
                {
                    label: 'Company Name',
                    field: 'companyName',
                },
                {
                    label: 'Company ID',
                    field: 'companyId',
                },
                {
                    label: 'SEC Code',
                    field: 'secCodes',
                    fieldType: new FieldType().with
                        .template<SecCode[]>(secCodes =>
                            secCodes.map((secCode: SecCode) => secCode.code).join(', ')
                        )
                        .thatIs.readOnly(),
                },
                {
                    label: 'Entry Description',
                    field: 'entryDescription',
                },
                {
                    label: 'Discretionary Data',
                    field: 'discretionaryData',
                },
                {
                    label: 'Batch Balance Requirements',
                    field: 'batchBalanceRequirements',
                },
                {
                    label: 'Transaction Type',
                    field: 'notOnUsTransactionTypes',
                },
            ],
        })
        .and.defaultValue(null)
        .and.onValueChange((record: any) => {
            const company = record.getField('achCompany');
            record.setField('entryDescription', company?.entryDescription);
            record.setField('secCode', company?.secCodes[0]?.code);
            record.setField('discretionaryData', company?.discretionaryData);
            record.setField('achCompanyId', company?.companyId);
        })
        .thatHas.label('ACH Company Name');

export const achCompanyId = fieldTypes.id.thatHas.maxLength(20).and.label('ACH Company ID');

export const achCompanyList = string;

export const achCompanySecCode = (filter: (secCode: SecCode) => boolean) =>
    new FieldType<string>().with
        .validator({
            name: 'length',
            validate: value => {
                if (!value) return true;
                return value?.length === 3;
            },
        })
        .and.exampleValue('PPD')
        .and.targetColumnWidth(80)
        .thatHas.label('SEC Code')
        .with.options<SecCode, Record<PaymentHeader>>({
            fetch: record => {
                const company = record.getField('achCompany');
                if (company) {
                    return Promise.resolve(company.secCodes ?? []);
                }
                return Promise.resolve([]);
            },
            filter,
            text: secCode => `${secCode.code}`,
            value: secCode => secCode.code,
        });

export const achCompanyMultiSelectSecCode = new FieldType<SecCode>().with
    .exampleValue('PPD')
    .and.targetColumnWidth(80)
    .thatHas.label('SEC Code');

export const achDiscretionaryData = new FieldType().with
    .validator(nachaAlphanumeric)
    .and.maxLength(20)
    .thatHas.label('Discretionary Data');

export const achEntryDescription = new FieldType().with
    .validator(nachaAlphanumeric)
    .and.maxLength(10)
    .thatHas.label('Entry Description');

export const achIdNumber = new FieldType<PaymentHeader['idNumber']>().with
    .validator(alphanumeric)
    .and.minColumnWidth(115)
    .and.targetColumnWidth(150)
    .and.maxColumnWidth(190)
    .and.maxLength(15);

export const achFipsCode = new FieldType().with.minLength(5).and.maxLength(7);

export const achPaymentFrequency = fieldTypes.frequency.and
    .options([
        { text: 'One Time', value: 'OneTime' },
        { text: 'Weekly', value: 'Weekly' },
        { text: 'Every Two Weeks', value: 'EveryTwoWeeks' },
        { text: 'Twice a Month', value: 'TwiceAMonth' },
        { text: 'Monthly', value: 'Monthly' },
        { text: 'Quarterly', value: 'Quarterly' },
        { text: 'Every Six Months', value: 'EverySixMonths' },
        { text: 'Yearly', value: 'Yearly' },
    ])
    .and.defaultValue({
        repeatOn: null,
        startOn: null,
        endOn: null,
        valueDate: null,
        nextPaymentDate: null,
        noEndDate: true,
        repeatOnDay1: null,
        repeatOnDay2: null,
        repeatOnLastBusinessDay: null,
        type: null,
        summary: '',
        id: 0,
        updatedBy: null,
        updatedDate: '0001-01-01T00:00:00',
    })
    .that.usesCustomPrint();

export const achFrequencyList = new FieldType<AchFrequencyViewModel>().with
    .defaultValue(null)
    .with.options<AchFrequencyViewModel>({
        data: [
            { id: '2', name: 'Weekly', value: 'Weekly', key: 'Weekly', isChecked: true },
            {
                id: '3',
                name: 'Every Two Weeks',
                value: 'Every Two Weeks',
                key: 'EveryTwoWeeks',
                isChecked: true,
            },
            {
                id: '4',
                name: 'Twice a Month',
                value: 'Twice a Month',
                key: 'TwiceAMonth',
                isChecked: true,
            },
            { id: '5', name: 'Monthly', value: 'Monthly', key: 'Monthly', isChecked: true },
            { id: '6', name: 'Quarterly', value: 'Quarterly', key: 'Quarterly', isChecked: true },
            {
                id: '7',
                name: 'Every Six Months',
                value: 'Every Six Months',
                key: 'EverySixMonths',
                isChecked: true,
            },
            { id: '8', name: 'Annually', value: 'Annually', key: 'Yearly', isChecked: true },
        ],
        text: 'value',
        value: model => model,
    })
    .and.multipleValues();

export const achPaymentAudit = new FieldType<string>().with.rowCount(5).and.template<string>(v => {
    const auditArray = v.split('\r\n');
    const auditText = auditArray.map(audit => html`${audit} <br />`);
    return html`<style>
            .audit-wrapper {
                overflow-y: scroll;
                min-width: 100px;
                min-height: 90px;
                max-height: 400px;
                padding: 5px;
                border: 1px solid #ccc;
                background-color: #eee;
            }
        </style>
        <div class="audit-wrapper">${auditText}</div> `;
});

export const achPaymentDate = fieldTypes.datePicker;

export const achPaymentName = new FieldType<string>().with
    .label((/* record */) => 'Payment Name')
    .and.minLength(1)
    .and.maxLength(50);

export const achPaymentRecipientName = new FieldType<string>().with
    .validator(nachaAlphanumeric)
    .and.maxLength(22)
    .and.minColumnWidth(100)
    .and.targetColumnWidth(175)
    .and.maxColumnWidth(200);

export const achPaymentStatus = new FieldType().with.label('Status');

interface PaymentStatusOption {
    value: string;
}

export const achPaymentStatusList = new FieldType<string>().with.options<PaymentStatusOption>({
    data: [
        { value: 'All' },
        { value: 'Pending Approval' },
        { value: 'Approval Rejected' },
        { value: 'Cancelled' },
        { value: 'Scheduled' },
    ],
    text: 'value',
    value: item => item.value,
});

export const achRestrictPayment = new FieldType().with
    .schema('boolean')
    .and.defaultValue(false)
    .thatHas.label('Restrict Payment')
    .with.formatter(yesNoFormatter);

export const achBank = (service = AchCompanyRequests) =>
    new FieldType<AchBankDto>().with
        .options({
            fetch: async () => {
                const request: () => Promise<AchBankDto[]> = service
                    ? service.getAchBanks
                    : AchCompanyRequests.getAchBanks;
                return request();
            },
            text: bank => `${bank.bankId} ${bank.name}`,
            value: bank => bank,
        })
        .thatHas.hashFunction((bank: AchBankDto) => bank?.bankId ?? '')
        .and.filtering()
        .and.search<AchBankDto>({
            title: 'Bank Lookup',
            columns: [
                {
                    label: 'Routing Number',
                    field: 'bankId',
                },
                {
                    label: 'Bank Name',
                    field: 'name',
                },
                {
                    label: 'Bank Address',
                    field: 'city',
                },
            ],
            actions: [],
        })
        .thatHas.template(value => `${value?.bankId || ''}`);

export const achSecCodeList = string;

export const achTransactionId = new FieldType().thatIs.readOnly();

export const addenda = fieldTypes.string.thatHas.maxLength(80) as FieldType<
    PaymentHeader['addenda']
>;

export const transactionType = string.with.options([
    { value: 'credit', text: 'Credit' },
    { value: 'debit', text: 'Debit' },
]);

export const restrictedPayment = boolean.with
    .formatter(yesNoFormatter)
    .with.label('Restrict Payment');

export const batchName = string.with.cellClass('batch-name').and.exampleValue('Payroll March 22');

export const testAchCompanyName = new FieldType<string | undefined>().thatIs.readOnly();

// eslint-disable-next-line @treasury/filename-match-export
export default {
    achPaymentName,
    achCompany,
    achCompanyName,
    achCompanyId,
    achCompanySecCode,
    achEntryDescription,
    achDiscretionaryData,
    achTransactionId,
    addenda,
    transactionType,
    restrictedPayment,
    batchName,
    testAchCompanyName,
};
