import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';

CreatePaymentSingleBatchController.$inject = [
    '$scope',
    '$state',
    '$modal',
    'toaster',
    'modalService',
    'companyAccountsService',
    'utilityService',
    'achCompaniesService',
    'achSettingsService',
    'achBatchService',
    'achPaymentsService',
    'holidaysService',
    '$timeout',
    'entitlementsService',
    'securityService',
    'downloadPageId',
    'activityService',
    'resourceType',
    'auditCode',
    'regexConstants',
    'dateConstants',
    'recipientValidatorService',
    'achPaymentTypes',
    'TmDi',
    '$q',
];

/**
 * @param { ReturnType<import('../../../../services/achPaymentsSrvc.js').AchPaymentsService> } achPaymentsService
 * @param { import('@jack-henry/frontend-utils/di').DiContainer } TmDi
 * @param { ReturnType<import('../../../../services/securitySrvc.js').default>} securityService
 */
// eslint-disable-next-line @treasury/filename-match-export
export default function CreatePaymentSingleBatchController(
    $scope,
    $state,
    $modal,
    toaster,
    modalService,
    companyAccountsService,
    utilityService,
    achCompaniesService,
    achSettingsService,
    achBatchService,
    achPaymentsService,
    holidaysService,
    $timeout,
    entitlementsService,
    securityService,
    downloadPageId,
    activityService,
    resourceType,
    auditCode,
    regexConstants,
    dateConstants,
    recipientValidatorService,
    achPaymentTypes,
    TmDi,
    $q
) {
    const analyticsService = TmDi.get(AnalyticsService);
    const createPaymentState = 'payables.ach.payments.create';
    const createTaxPaymentState = 'payables.ach.payments.createTaxPayment';
    const createFromBatchState = 'payables.ach.payments.createFromBatch';
    const createFromTaxTemplateState = 'payables.ach.payments.createFromTaxTemplate';
    const batchListState = 'payables.ach.batch-list';
    const taxTemplateListState = 'payables.ach.payments.tax-templates';
    const paymentListState = 'payables.ach.payment-list';

    $scope.canSaveRecipients = entitlementsService.hasEntitlement('Create Recipient');
    $scope.wizardController = {};
    $scope.downloadPageId = downloadPageId.ConfirmationAchPayment;
    $scope.title = 'Create ACH Payment';
    $scope.reversalId = $state.params.reversalId;

    if ($state.params.clearSelected) {
        $scope.setSelectedBatches(null);
    }

    $scope.nextAchBusinessDate = moment().format('MM/DD/YYYY');
    $scope.isNewPayment = true;
    $scope.form = {};
    $scope.achCompanies = [];
    $scope.nonIATAchCompanies = [];
    $scope.hasNonIatAchCompanies = false;
    $scope.ccdAchCompanies = [];
    $scope.secCodes = [];
    $scope.searchFilters = {
        text: '',
        prenote: false,
        hold: false,
    };
    $scope.alphanumericRegex = regexConstants.AlphaNumericRegex;
    $scope.numericRegex = /^\d+$/;
    $scope.recipientsTableController = {};

    $scope.create = createPayment;
    $scope.resetErrors = resetErrors;
    $scope.confirmCancel = confirmCancel;
    $scope.findAchCompany = findAchCompany;
    $scope.findCCDAchCompany = findCCDAchCompany;
    $scope.validateAchCompany = validateAchCompany;
    $scope.filterRecipients = filterRecipients;
    $scope.calculateAmounts = calculateAmounts;
    $scope.getRecipientErrors = getRecipientErrors;
    $scope.saveAsBatch = saveAsBatch;
    $scope.getFrequencySummary = getFrequencySummary;
    $scope.checkRepeatOnDays = checkRepeatOnDays;
    $scope.getEndOnDate = getEndOnDate;
    $scope.showAddendaModal = showAddendaModal;
    $scope.getMaximumDate = getMaximumDate;
    $scope.resetValues = resetValues;
    $scope.enableSaveRecipients = enableSaveRecipients;
    $scope.print = print;
    $scope.processingCutoff = {};
    $scope.disableToday = false;
    $scope.$watch('batch.achCompany', getOffsetAccounts);
    $scope.$watch('batch.achCompany', getSecCodes);
    $scope.uploadNacha = uploadNacha;
    $scope.fromTemplate = fromTemplate;
    $scope.entryType = { selected: 'M' };
    $scope.numbers = dateConstants.daysInMonth;
    $scope.weekDays = dateConstants.weekDays;
    $scope.review = review;
    $scope.cutoffPassed = false;
    $scope.getHolidays = getHolidays;
    $scope.back = back;
    $scope.counts = {
        prenote: 0,
        hold: 0,
    };
    $scope.isCreatedFromBatch = isCreatedFromBatch();
    $scope.masterRecipients = $state.params.masterRecipients;
    $scope.showEntryTypeOptions =
        !$scope.isCreatedFromBatch && $state.params.masterRecipients == null;
    $scope.hasErrors = false;
    $scope.createAnotherPayment = createAnotherPayment;
    $scope.setPendingApprovalPaymentMessage = setPendingApprovalPaymentMessage;
    $scope.setProcessingPaymentMessage = setProcessingPaymentMessage;
    $scope.setScheduledPaymentMessage = setScheduledPaymentMessage;
    $scope.setACHTemplateCreatedMessage = setACHTemplateCreatedMessage;
    $scope.setPaymentErrorMessage = setPaymentErrorMessage;
    $scope.shouldShowOffsetCredit = shouldShowOffsetCredit;
    $scope.shouldShowOffsetDebit = shouldShowOffsetDebit;
    $scope.isDebitTransaction = isDebitTransaction;
    $scope.secCodeOptions = [];
    $scope.getSecCodes = getSecCodes;
    $scope.allowOnUsAccessManagementEntitlement = entitlementsService.hasEntitlement(
        'Feature.ACH.AllowOnUsAccessManagement'
    );

    $scope.$watch(
        () => $scope.batch.frequency,
        () => {
            if ($scope.batch && $scope.batch.frequency) {
                $scope.endOnDate = getEndOnDate(
                    $scope.batch.frequency.startOn,
                    $scope.batch.frequency.type
                );
                $timeout(() => {
                    $scope.$apply();
                });
            }
        },
        true
    );

    function isCreatedFromBatch() {
        return (
            $state.current.name === createFromBatchState ||
            $state.current.name === createFromTaxTemplateState
        );
    }

    function isBatch() {
        return $state.current.name === createFromBatchState;
    }

    function isTaxTemplate() {
        return $state.current.name === createFromTaxTemplateState;
    }

    function isPayment() {
        return $state.current.name === createPaymentState;
    }

    function isTaxPayment() {
        return $state.current.name === createTaxPaymentState;
    }

    function formatDate(dt) {
        return moment(new Date(dt)).format('MM/DD/YYYY');
    }

    function loadCutoffTimes() {
        companyAccountsService.getCutoffTimesByProductType('SameDayAch').then(response => {
            $scope.processingCutoff = response;
            const timeout =
                response.processingCutoff.allowSameDay === false
                    ? 0
                    : moment(
                          `${moment(response.currentFiTime).format('L')} ${
                              response.processingCutoff.cutoffTime
                          }`
                      ).diff(response.currentFiTime);
            $scope.cutoffPassed =
                moment(
                    `${moment(response.currentFiTime).format('L')} ${
                        response.processingCutoff.cutoffTime
                    }`
                ).diff(response.currentFiTime) < 0;
            $scope.disableToday = response.processingCutoff.allowSameDay === false;
            getHolidays(timeout);
        });
    }

    function checkTimeout(timeout) {
        $timeout(() => {
            if ($scope.payment && $scope.payment.frequency) {
                if (
                    moment($scope.payment.frequency.effectiveDate).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
                ) {
                    utilityService
                        .getAchNextBusinessDay(
                            $scope.holidayDates,
                            $scope.disableToday,
                            $scope.cutoffPassed
                        )
                        .then(response => {
                            $scope.payment.frequency.effectiveDate = formatDate(response);
                        });
                }
                if (
                    moment($scope.payment.frequency.startOn).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
                ) {
                    utilityService
                        .getAchNextBusinessDay(
                            $scope.holidayDates,
                            $scope.disableToday,
                            $scope.cutoffPassed
                        )
                        .then(response => {
                            $scope.payment.frequency.startOn = formatDate(response);
                        });
                }
                if (
                    moment($scope.payment.frequency.endOn).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
                ) {
                    utilityService
                        .getAchNextBusinessDay(
                            $scope.holidayDates,
                            $scope.disableToday,
                            $scope.cutoffPassed
                        )
                        .then(response => {
                            $scope.payment.frequency.endOn = formatDate(response);
                        });
                }
            }
            if ($scope.batch && $scope.batch.frequency) {
                if (
                    moment($scope.batch.frequency.effectiveDate).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
                ) {
                    utilityService
                        .getAchNextBusinessDay(
                            $scope.holidayDates,
                            $scope.disableToday,
                            $scope.cutoffPassed
                        )
                        .then(response => {
                            $scope.batch.frequency.effectiveDate = formatDate(response);
                        });
                }
                if (
                    moment($scope.batch.frequency.startOn).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
                ) {
                    utilityService
                        .getAchNextBusinessDay(
                            $scope.holidayDates,
                            $scope.disableToday,
                            $scope.cutoffPassed
                        )
                        .then(response => {
                            $scope.batch.frequency.startOn = formatDate(response);
                        });
                }
                if (
                    moment($scope.batch.frequency.endOn).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
                ) {
                    utilityService
                        .getAchNextBusinessDay(
                            $scope.holidayDates,
                            $scope.disableToday,
                            $scope.cutoffPassed
                        )
                        .then(response => {
                            $scope.batch.frequency.endOn = formatDate(response);
                        });
                }
            }

            if (timeout > 0) {
                toaster.alert('Cutoff Time Passed', 'Cannot create ACH for today.');
            }
        }, timeout);
    }

    function showAddendaModal(recipient) {
        const modalInstance = $modal.open({
            template: require('../../batch/views/addendaModalView.html'),
            size: 'lg',
            controller: 'AddendaModalController',
            backdrop: 'static',
            resolve: {
                data() {
                    return {
                        addendaTypes: [],
                        batch: $scope.batch,
                        recipient,
                    };
                },
                readOnly() {
                    return true;
                },
            },
        });

        modalInstance.result.then(data => {
            recipient.addenda = data;
        });
    }

    function loadReversal() {
        if (!$scope.payment) {
            $state.go('payables.ach.payment-detail', {
                id: $scope.reversalId,
                type: $state.params.type,
                list: $state.params.list,
            });
        } else {
            $scope.batch.recipients = $scope.batch.recipients.reduce((list, recipient) => {
                if (!recipient.hold && !recipient.prenote) {
                    if (recipient.transactionType === 'CR') {
                        recipient.transactionType = 'DR';
                    } else {
                        recipient.transactionType = 'CR';
                    }
                    list.push(recipient);
                }
                return list;
            }, []);
            $scope.batch.name += ' - Reversal';
            $scope.batch.entryDescription = 'REVERSAL';
            $scope.batch.transactionId = null;
            $scope.batch.templateName = null;
            $scope.batch.reversalAchPaymentId = parseInt($scope.reversalId);
            review();
        }
    }

    function resetErrors() {
        $scope.form.$setValidity('onUsTransactionsRequired', true);
        $scope.form.$setValidity('onUsAmountRequiredPerBatch', true);
        $scope.form.$setValidity('batchBalanceRequirements', true);
    }

    function getAchSettings() {
        achSettingsService.get().then(response => {
            $scope.achSettings = response;
        });
    }

    function saveAsBatch(batch) {
        $modal.open({
            template: require('../views/saveAsBatchModalTemplate.html'),
            size: 'md',
            controller: 'SaveAsBatchModalController',
            backdrop: 'static',
            resolve: {
                batch() {
                    return batch;
                },
            },
        });
    }

    function getOffsetAccounts() {
        if (
            $scope.batch &&
            $scope.batch.achCompany &&
            $scope.batch.achCompany.id &&
            $scope.batch.achCompany.batchBalanceRequirements !== 'Balanced'
        ) {
            achCompaniesService.getOffsetAccounts($scope.batch.achCompany.id).then(response => {
                $scope.offsetAccounts = response;
            });
        } else if ($scope.batch && $scope.batch.offsetAccount) {
            $scope.batch.offsetAccount = null;
        }
    }

    function getSecCodes() {
        $scope.secCodeOptions = $scope.batch.achCompany.secCodes.filter(
            code => code.code !== 'IAT'
        );
    }

    function back() {
        if ($scope.reversalId) {
            $state.go('payables.ach.payment-detail', {
                id: $scope.reversalId,
                type: 'view',
                list: 'payment-list',
            });
        } else {
            $scope.batch.errorMessage = '';
            $scope.hasErrors = false;
            $scope.wizardController.goToPreviousStep();
        }
    }

    function resetValues(type) {
        if (type === 'Monthly') {
            $scope.batch.frequency.repeatOnDay1 = null;
        } else if (type === 'Twice a Month') {
            $scope.batch.frequency.repeatOnDay2 = null;
            $scope.form.repeatOnDay1.$setValidity('doesNotMatch', true);
            $scope.form.repeatOnDay2.$setValidity('doesNotMatch', true);
        }
    }

    function confirmCancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel Payment Information?',
                submit(result) {
                    $modalInstance.close();
                    goToList();
                },
            };

            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            goToList();
        }
    }

    function goToList() {
        if (isBatch()) $state.go(batchListState);
        else if (isTaxTemplate()) $state.go(taxTemplateListState);
        else $state.go(paymentListState);
    }

    function getAchCompanies() {
        $q((resolve, reject) => {
            achCompaniesService.getAll().catch(reject).then(resolve);
        }).then(data => {
            $scope.achCompanies = data;
            $scope.nonIATAchCompanies = achCompaniesService.getNonIATAchCompanies(
                $scope.achCompanies
            );
            $scope.hasNonIatAchCompanies = !!$scope.nonIATAchCompanies.length;
            $scope.ccdAchCompanies = achCompaniesService.getCCDAchCompanies($scope.achCompanies);
        });
    }

    function getAccountTypes() {
        $scope.accountTypes = achBatchService.getAccountTypes();
    }

    function getTransactionTypes() {
        $scope.transactionTypes = achBatchService.getTransactionTypes();
    }

    function findAchCompany() {
        const modalInstance = $modal.open({
            template: require('../../batch/views/achCompaniesModalView.html'),
            size: 'md',
            controller: 'AchCompaniesModalController',
            backdrop: 'static',
            resolve: {
                data() {
                    return $scope.nonIATAchCompanies;
                },
            },
        });

        modalInstance.result.then(data => {
            setAchCompanyAttributes(data);
        });
    }

    function findCCDAchCompany() {
        const modalInstance = $modal.open({
            template: require('../../batch/views/achCompaniesModalView.html'),
            size: 'md',
            controller: 'AchCompaniesModalController',
            backdrop: 'static',
            resolve: {
                data() {
                    return $scope.ccdAchCompanies;
                },
            },
        });

        modalInstance.result.then(data => {
            setAchCompanyAttributes(data);
        });
    }

    function uploadNacha() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Close',
                actionButtonText: 'Cancel Edits',
                headerText: 'Confirm Cancellation',
                bodyText:
                    'You have changes on the page, are you sure you would like to leave the page without saving?',
                submit(result) {
                    activityService.userInteractionAudit(
                        resourceType.PaymentResources,
                        auditCode.CancelCreateAchPayment
                    );
                    $modalInstance.close();
                    $state.go('payables.ach.payments.uploadPayment');
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
            $modalInstance.result.then(null, () => {
                $scope.entryType.selected = 'M';
            });
        } else {
            activityService.userInteractionAudit(
                resourceType.PaymentResources,
                auditCode.CancelCreateAchPayment
            );
            $state.go('payables.ach.payments.uploadPayment');
        }
    }

    function fromTemplate() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Close',
                actionButtonText: 'Cancel Edits',
                headerText: 'Confirm Cancellation',
                bodyText:
                    'You have changes on the page, are you sure you would like to leave the page without saving?',
                submit(result) {
                    activityService.userInteractionAudit(
                        resourceType.PaymentResources,
                        auditCode.CancelCreateAchPayment
                    );
                    $modalInstance.close();
                    createFromTemplate();
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
            $modalInstance.result.then(null, () => {
                $scope.entryType.selected = 'M';
            });
        } else {
            activityService.userInteractionAudit(
                resourceType.PaymentResources,
                auditCode.CancelCreateAchPayment
            );
            createFromTemplate();
        }
    }

    function createFromTemplate() {
        if (isPayment()) $state.go(createFromBatchState);
        if (isTaxPayment()) $state.go(createFromTaxTemplateState);
    }

    function validateAchCompany() {
         const validCompanies = $scope.achCompanies.filter(
                item =>
                    item.companyName === $scope.batch.achCompanyName &&
                    item.id === $scope.batch.achCompany.id
            )    
        return validCompanies.length > 0;    
        
    }

    function setAchCompanyAttributes(data) {
        $scope.batch.achCompany = data;
        $scope.batch.achCompanyName = data.companyName || '';
        $scope.batch.achCompanyId = data.companyId || '';
        $scope.batch.secCode = getSecCode(data);
        $scope.secCodes = data.secCodes || [];
        $scope.batch.entryDescription = data.entryDescription || '';
        $scope.batch.discretionaryData = data.discretionaryData || '';

        if (data.prefundingDays > 0) {
            $scope.batch.offsetAccount = null;
        }
        setOnUsDefaultTransactionType();
    }

    function getSecCode(company) {
        const nonIatCodes = company.secCodes.filter(code => code.code !== 'IAT');
        switch ($scope.batch.achPaymentTypeId) {
            case achPaymentTypes.AchStateTaxPayment:
            case achPaymentTypes.AchFederalTaxPayment:
                return hasCcdCode(company) ? 'CCD' : '';
            default:
                return company.secCodes && company.secCodes.length ? nonIatCodes[0].code : '';
        }
    }

    function hasCcdCode(company) {
        if (!(company.secCodes && company.secCodes.length)) return false;

        return company.secCodes.some(item => item.code === 'CCD');
    }

    $scope.onChange = function (e) {
        if (!isNaN(Date.parse($scope.batch.frequency.startOn))) {
            const dt = new Date($scope.batch.frequency.startOn);

            if ($scope.batch.frequency.type === 'Weekly') {
                $scope.endOnDate = moment(dt).add(1, 'week').format('MM/DD/YYYY');
            } else if ($scope.batch.frequency.type === 'Every Two Weeks') {
                $scope.endOnDate = moment(dt).add(2, 'weeks').format('MM/DD/YYYY');
            } else if (
                $scope.batch.frequency.type === 'Twice a Month' ||
                $scope.batch.frequency.type === 'Monthly'
            ) {
                $scope.endOnDate = moment(dt).add(1, 'month').format('MM/DD/YYYY');
            } else if ($scope.batch.frequency.type === 'Quarterly') {
                $scope.endOnDate = moment(dt).add(3, 'months').format('MM/DD/YYYY');
            } else if ($scope.batch.frequency.type === 'Every Six Months') {
                $scope.endOnDate = moment(dt).add(6, 'months').format('MM/DD/YYYY');
            } else if ($scope.batch.frequency.type === 'Annually') {
                $scope.endOnDate = moment(dt).add(1, 'year').format('MM/DD/YYYY');
            }
        }
    };

    function getEndOnDate(date, frequencyType) {
        let endOnDate = moment().format('MM/DD/YYYY');

        if (!isNaN(Date.parse(date))) {
            const dt = new Date(date);

            if (frequencyType === 'Weekly') {
                endOnDate = moment(dt).add(1, 'week').format('MM/DD/YYYY');
            } else if (frequencyType === 'Every Two Weeks') {
                endOnDate = moment(dt).add(2, 'weeks').format('MM/DD/YYYY');
            } else if (frequencyType === 'Twice a Month' || frequencyType === 'Monthly') {
                endOnDate = moment(dt).add(1, 'month').format('MM/DD/YYYY');
            } else if (frequencyType === 'Quarterly') {
                endOnDate = moment(dt).add(3, 'months').format('MM/DD/YYYY');
            } else if (frequencyType === 'Every Six Months') {
                endOnDate = moment(dt).add(6, 'months').format('MM/DD/YYYY');
            } else if (frequencyType === 'Annually') {
                endOnDate = moment(dt).add(1, 'year').format('MM/DD/YYYY');
            }
        }
        return endOnDate;
    }

    function getMaximumDate(startDate, frequencyType) {
        let date = moment(new Date(startDate));

        if (frequencyType === 'Every Six Months') {
            date = date.add(6, 'months');
        } else if (frequencyType === 'Monthly') {
            date = date.add(1, 'month');
        } else if (frequencyType === 'Quarterly') {
            date = date.add(3, 'months');
        } else if (frequencyType === 'Annually') {
            date = date.add(1, 'year');
        }

        return date.format('MM/DD/YYYY');
    }

    $scope.toOptions = {
        disableDates(date) {
            if (
                $scope.disableToday &&
                moment(date).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
            ) {
                return true;
            }
            if (date) {
                return holidaysService.compareDates(date, $scope.holidayDates);
            }
            return false;
        },
    };

    function getHolidays(timeout) {
        holidaysService.getAll().then(response => {
            $scope.holidayDates = response.map(item => item.date);
            checkTimeout(timeout);

            utilityService
                .getAchNextEffectiveDate(
                    $scope.holidayDates,
                    $scope.disableToday,
                    $scope.cutoffPassed
                )
                .then(nextAchEffectiveDate => {
                    if ($scope.payment.reversalAchPaymentId) {
                        setReversalEffectiveDate(nextAchEffectiveDate);
                    } else if (!$scope.payment.frequency.effectiveDate) {
                        $scope.payment.frequency.effectiveDate = formatDate(nextAchEffectiveDate);
                        $scope.batch.frequency.effectiveDate = formatDate(nextAchEffectiveDate);
                    }

                    $scope.nextAchBusinessDate = formatDate(nextAchEffectiveDate);
                });
        });
    }

    function setReversalEffectiveDate(nextBusinessDay) {
        achPaymentsService.getDetails($scope.payment.id).then(response => {
            const orgEffectiveDate = formatDate(response.payment.frequency.effectiveDate);
            if (new Date(orgEffectiveDate) < new Date(nextBusinessDay)) {
                $scope.payment.frequency.effectiveDate = formatDate(nextBusinessDay);
                $scope.batch.frequency.effectiveDate = formatDate(nextBusinessDay);
            } else {
                $scope.payment.frequency.effectiveDate = orgEffectiveDate;
                $scope.batch.frequency.effectiveDate = orgEffectiveDate;
            }
        });
    }

    function loadFrequencyOptions() {
        $scope.frequencies = [
            {
                name: 'One Time',
                key: 'One Time',
            },
            {
                name: 'Weekly',
                key: 'Weekly',
            },
            {
                name: 'Every Two Weeks',
                key: 'Every Two Weeks',
            },
            {
                name: 'Twice a Month',
                key: 'Twice a Month',
            },
            {
                name: 'Monthly',
                key: 'Monthly',
            },
            {
                name: 'Quarterly',
                key: 'Quarterly',
            },
            {
                name: 'Every Six Months',
                key: 'Every Six Months',
            },
            {
                name: 'Annually',
                key: 'Annually',
            },
        ];
    }

    function checkRepeatOnDays() {
        if (!$scope.batch.frequency.repeatOnLastBusinessDay) {
            const isValid1 =
                $scope.batch.frequency.repeatOnDay1 < $scope.batch.frequency.repeatOnDay2 ||
                $scope.batch.frequency.repeatOnDay2 === '';
            const isValid2 =
                $scope.batch.frequency.repeatOnDay2 > $scope.batch.frequency.repeatOnDay1 ||
                $scope.batch.frequency.repeatOnDay1 === '';

            if (
                $scope.batch.frequency.repeatOnDay1 !== undefined &&
                $scope.batch.frequency.repeatOnDay2 !== undefined &&
                $scope.batch.frequency.repeatOnDay2 !== ''
            ) {
                $scope.form.repeatOnDay1.$setValidity('doesNotMatch', isValid1);
                $scope.form.repeatOnDay2.$setValidity('doesNotMatch', isValid2);
            }
        }
    }

    function getFrequencySummary(batch) {
        if (batch !== null && batch !== undefined) {
            return achPaymentsService.summarizeFrequency(batch.frequency);
        }
    }

    function calculateAmounts() {
        $scope.batch.debitAmount = 0;
        $scope.batch.creditAmount = 0;

        angular.forEach($scope.batch.recipients, item => {
            if (!item.hold) {
                const key = item.transactionType === 'DR' ? 'debitAmount' : 'creditAmount';
                $scope.batch[key] = parseFloat(
                    ($scope.batch[key] + parseFloat(item.amount) || 0).toFixed(2)
                );
            }
        });
    }

    function review() {
        if (!$scope.reversalId) {
            $scope.wizardController.goToNextStep();
        }
        calculateAmounts();
        filterRecipients();
        achBatchService.updateFilterCounts($scope.counts, $scope.batch.recipients);
    }

    function isDebitTransaction() {
        return $scope.batch.recipients.some(recipient => recipient.transactionType === 'DR');
    }

    function getAchSettingsAllowUnbalancedPayments() {
        return $scope.achSettings?.allowUnbalancedPayments ?? true;
    }

    function shouldAllowUnbalancedPayments() {
        return (
            ($scope.batch.achCompany.prefundingDays > 0 && !isDebitTransaction()) ||
            ($scope.batch.achCompany.allowUnbalancedPayments &&
                getAchSettingsAllowUnbalancedPayments())
        );
    }

    function shouldShowOffsetCredit(isReview, isConfirmation) {
        if (!shouldShowOffsetAccount(isReview, isConfirmation)) return false;
        return isConfirmation || shouldAllowUnbalancedPayments();
    }

    function shouldShowOffsetDebit(isReview, isConfirmation) {
        if (!shouldShowOffsetAccount(isReview, isConfirmation)) return false;
        if (!isReview) return false;
        return !shouldAllowUnbalancedPayments();
    }

    function getRecipientErrors() {
        const errors = [];
        const invalidRecipientCount = recipientValidatorService(
            $scope.batch.recipients,
            $scope.batch.achCompany
        ).countErrors();

        if (invalidRecipientCount > 0) {
            if (invalidRecipientCount === 1) {
                errors.push(`${invalidRecipientCount} recipient has one or more invalid fields.`);
            } else {
                errors.push(`${invalidRecipientCount} recipients have one or more invalid fields.`);
            }
        }

        if ($scope.batch.entryDescription !== undefined && $scope.batch.entryDescription.$invalid) {
            errors.push(
                'Entry description is a required field.  If uploading a file, the entry description in the UI must match the entry description in the file being uploaded.'
            );
        }

        for (const type in $scope.form.$error) {
            switch (type) {
                case 'onUsTransactionsRequired':
                    errors.push(
                        `At least ${$scope.batch.achCompany.onUsTransactionsRequired} recipients are required to have "On Us" routing numbers.`
                    );
                    break;

                case 'onUsAmountRequiredPerBatch':
                    errors.push(
                        `At least ${$scope.batch.achCompany.onUsAmountRequiredPerBatch}% of the batch amount must be allocated to "On Us" routing numbers.`
                    );
                    break;

                case 'batchBalanceRequirements':
                    if ($scope.batch.achCompany.batchBalanceRequirements === 'Balanced') {
                        errors.push('Debit amount total must be equal to Credit amount total.');
                    } else if (
                        $scope.batch.achCompany.batchBalanceRequirements ===
                        'Unbalanced - Full Offset'
                    ) {
                        errors.push(
                            `Transaction type must be set to "${$scope.batch.recipients[0].transactionType}" on all recipients.`
                        );
                    } else if (
                        $scope.batch.achCompany.batchBalanceRequirements ===
                        'Unbalanced - Partial Offset'
                    ) {
                        errors.push(
                            'Debit and Credit amounts must be greater than zero and different from one another.'
                        );
                    }

                    break;
            }
        }
        return errors;
    }

    function filterRecipients() {
        let filteredArray = $scope.batch.recipients.filter(
            recipient =>
                $scope.searchFilters.text === '' ||
                (recipient.name &&
                    (recipient.name.toLowerCase().indexOf($scope.searchFilters.text) !== -1 ||
                        recipient.name.toUpperCase().indexOf($scope.searchFilters.text) !== -1)) ||
                (recipient.idNumber &&
                    recipient.idNumber.indexOf($scope.searchFilters.text) !== -1) ||
                (recipient.accountNumber &&
                    recipient.accountNumber.indexOf($scope.searchFilters.text) !== -1)
        );

        if ($scope.searchFilters.prenote) {
            filteredArray = filteredArray.filter(
                recipient => $scope.searchFilters.prenote && recipient.prenote
            );
        }

        if ($scope.searchFilters.hold) {
            filteredArray = filteredArray.filter(
                recipient => $scope.searchFilters.hold && recipient.hold
            );
        }
        $scope.filteredRecipients = filteredArray;
        return filteredArray;
    }

    function createPayment() {
        if ($scope.creatingPayment === true) return;
        $scope.creatingPayment = true;
        let actionType;
        if ($scope.payment) {
            actionType = 'Initiate Payment From Batch';
        } else {
            actionType =
                $scope.batch.frequency.type === 'One Time'
                    ? 'Create One time ACH Payment'
                    : 'Create Recurring ACH Payment';
        }
        const batch = { ...$scope.batch };
        batch.recipients.forEach(recipient => {
            if (recipient.addenda?.length < 1) {
                // eslint-disable-next-line no-param-reassign
                delete recipient.addenda;
            }
        });
        securityService
            .verifyUser(actionType, batch, securityMessage =>
                achPaymentsService.create({
                    duplicatePreventionId: $scope.wizardController?.duplicatePreventionId,
                    ...$scope.batch,
                    securityMessage,
                })
            )
            .then(response => {
                const { payment, successMessage, errorMessage } = response;
                const { transactionId, achCompanyId: companyId, debitAmount, creditAmount, status} = payment;

                $scope.id = payment.id;
                $scope.batch.successMessage = successMessage;
                $scope.batch.errorMessage = errorMessage.replace(
                    /(?:\r\n|\r|\n)/g,
                    '<br>'
                );
                $scope.batch.templateName = payment.templateName;
                $scope.batch.transactionId = payment.transactionId;
                $scope.batch.audit = payment.audit;
                $scope.batch.numberOfApprovalsNeeded = payment.numberOfApprovalsNeeded;
                $scope.batch.status = status;
                $scope.paymentStatus = status;
                $scope.creatingPayment = false;

                analyticsService.track(AnalyticsEvent.AchPayment, {
                    companyId,
                    transactionId,
                    creditAmount,
                    debitAmount,
                    status
                })

                if (!errorMessage || status === 'Failed') {
                    $scope.wizardController.goToNextStep();
                }

            });
    }

    function setProcessingPaymentMessage() {
        return !!($scope.isNewPayment === true && $scope.paymentStatus === 'Initiated');
    }

    function setScheduledPaymentMessage() {
        return !!($scope.isNewPayment === true && $scope.paymentStatus === 'Scheduled');
    }

    function setPendingApprovalPaymentMessage() {
        return $scope.paymentStatus === 'Pending Approval';
    }

    function setACHTemplateCreatedMessage() {
        return !!(
            $scope.batch.numberOfApprovalsNeeded === 0 &&
            !$scope.batch.errorMessage &&
            !$scope.isNewPayment
        );
    }

    function setPaymentErrorMessage() {
        return !!$scope.batch.errorMessage;
    }

    function createBatch() {
        if ($state.params.payment) {
            $scope.batch = $state.params.payment;
            $scope.payment = $state.params.payment;

            // coming from master recipients list after payment header has already been created.
            const goToManageRecipients = function () {
                if ($scope.wizardController.goToStep) {
                    $scope.wizardController.goToStep(1);
                } else {
                    $timeout(goToManageRecipients);
                }
            };
            goToManageRecipients();
        } else {
            $scope.batch = $scope.payment || new achBatchService.Batch();
            $scope.batch.frequency = {
                effectiveDate: null,
                type: 'One Time',
                startOn: null,
                endOn: null,
            };
        }

        if ($state.params.masterRecipients) {
            $scope.batch.recipients = $scope.batch.recipients.filter(recipientHasValue);
            $scope.batch.recipients = $scope.batch.recipients.concat(
                $state.params.masterRecipients
            );
        }
    }

    function recipientHasValue(recipient) {
        return !(
            isNullOrEmpty(recipient.name) &&
            isNullOrEmpty(recipient.idNumber) &&
            isNullOrEmpty(recipient.accountNumber) &&
            isNullOrEmpty(recipient.routingNumber) &&
            recipient.amount === 0 &&
            (recipient.addenda == null || isNullOrEmpty(recipient.addenda[0].value)) &&
            recipient.accountType === 'Checking' &&
            recipient.transactionType === 'CR' &&
            !recipient.hold &&
            !recipient.prenote
        );
    }

    function isNullOrEmpty(stringValue) {
        return stringValue == null || stringValue.length === 0;
    }

    function enableSaveRecipients() {
        return entitlementsService.hasEntitlement('Create Recipient') && $scope.isConfirmation;
    }

    function createAnotherPayment() {
        $state.go(
            'payables.ach.payments.create',
            {
                masterRecipients: null,
            },
            {
                reload: true,
            }
        );
    }

    function shouldShowOffsetAccount(isReview, isConfirmation) {
        return (
            $scope.isAchPrefundingEntitled &&
            (isReview || isConfirmation) &&
            $scope.batch.achCompany.companyName &&
            $scope.batch.achCompany.batchBalanceRequirements !== 'Balanced'
        );
    }

    async function populateAchCompany() {
        const achCompanies = await achCompaniesService.getAll();
        const achCompany = achCompanies.find(company => company.id === $scope.batch.achCompany.id);
        if (achCompany) {
            $scope.batch.achCompany.prefundingDays = achCompany.prefundingDays;
            $scope.batch.achCompany.offsetAccountNumber = achCompany.offsetAccountNumber;
            $scope.batch.achCompany.allowUnbalancedPayments = achCompany.allowUnbalancedPayments;
        }
    }

    function setOnUsDefaultTransactionType() {
        const notOnUsTransactionType = $scope.batch?.achCompany?.notOnUsTransactionTypes;
        const isTransactionTypeCrDrOnly =
            notOnUsTransactionType === 'Debit Only' || notOnUsTransactionType === 'Credit Only';

        if (
            $scope.allowOnUsAccessManagementEntitlement &&
            $scope.achSettings?.allowOnUsAccessManagement &&
            isTransactionTypeCrDrOnly &&
            $scope.batch.recipients[0]?.default === true
        ) {
            if (notOnUsTransactionType === 'Debit Only')
                $scope.batch.recipients[0].transactionType = 'DR';
            if (notOnUsTransactionType === 'Credit Only')
                $scope.batch.recipients[0].transactionType = 'CR';
        }
    }


    (async function () {
        // init
        createBatch();
        if ($scope.reversalId) {
            loadReversal();
        }

        if (!$scope.payment) {
            $scope.payment = {
                frequency: {
                    effectiveDate: '',
                },
            };
        }

        getAchCompanies();
        getAccountTypes();
        getTransactionTypes();
        getAchSettings();
        loadFrequencyOptions();
        loadCutoffTimes();
        $scope.restrict = entitlementsService.hasEntitlement('Restricted Batch');
        $scope.editBatchEntitlement = entitlementsService.hasEntitlement('Full Edit Ach Template');
        $scope.achFull = entitlementsService.hasEntitlement('ACH, Payment, Full Edit');
        $scope.achPartial = entitlementsService.hasEntitlement('ACH, Payment, Partial Edit');
        $scope.achCreate = entitlementsService.hasEntitlement('ACH, Batch, CreatePayment');
        $scope.isAchPrefundingEntitled = entitlementsService.hasEntitlement(
            'Feature.ACH.AllowUnbalancedPayments'
        );
        if (!$scope.isCreatedFromBatch && !$scope.reversalId) {
            $scope.hasFullEdit = $scope.achCreate; // for a regular payment you just need create access to edit the header fields
            $scope.hasPartialEdit = $scope.achCreate;
            $scope.$watch(
                () => $scope.batch.achCompanyName,
                data => {
                    if (data && $scope.batch.achCompany.companyName !== data) {
                        if (typeof data === 'object') {
                            setAchCompanyAttributes(data);
                        } else {
                            angular.forEach($scope.achCompanies, item => {
                                if (data === item.companyName) {
                                    setAchCompanyAttributes(item);
                                }
                            });

                            if (!validateAchCompany()) {
                                setAchCompanyAttributes({
                                    companyName: data,
                                });
                            }
                        }
                    }
                }
            );
        } else {
            $scope.hasFullEdit = $scope.achFull; // for a batch payment you need full or partial (depending on the field)
            $scope.hasPartialEdit = $scope.achPartial || $scope.achFull;
            $scope.secCodes = $scope.payment.achCompany.secCodes;
        }
        await populateAchCompany();

        $scope.endOnDate = moment().format('MM/DD/YYYY');

        achBatchService.updateFilterCounts($scope.counts, $scope.batch.recipients);
    })();
}